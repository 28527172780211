/*-------------------------------------------------------------------------------------------------------------------------------*/
/* ANIMATIONS */
/*-------------------------------------------------------------------------------------------------------------------------------*/

/* Fade-in */
.fade-in{
	transition: all 0.4s ease-out;
	opacity: 0;
	&-left {
		transition: all 0.8s ease-out;
		opacity: 0;
		transform: translate3d(15px, 0, 0);
	}
}
.fade-in,
.fade-in-left{
	&.animated {
		transform: none;
		opacity: 1;
	}
}

.animation.animated{
	.fade-in,
	.fade-in-left{
		transform: none;
		opacity: 1;
	}
}

/* Slide */
.bg-anim{
	background-color: #FCFCFE;
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	bottom: 0;
	right: 0;
	z-index: 9;
}
.anim-slide{
	&-bottom {
		transition-duration: 1s;
		&.dur-1_5{
			transition-duration: 2s;
		}
		&.animated{
			height: 0;
		}
	}
	&-right {
		transition-duration: 1s;
		&.animated{
			width: 0;
		}
	}
}

.animation.animated{
	.anim-slide{
		&-bottom {
			height: 0;
		}
		&-right {
			width: 0;
		}
	}
}

/* Delay */
.delay-01 {
	transition-delay: .3s;
}

.delay-02 {
	transition-delay: .6s;
}

.delay-03 {
	transition-delay: .9s;
}

.delay-04 {
	transition-delay: 1.2s;
}

.delay-05 {
	transition-delay: 1.5s;
}

.delay-06 {
	transition-delay: 1.8s;
}

.delay-07 {
	transition-delay: 2.1s;
}
