/*==========  Banner Style  ==========*/
.banner{
  position: relative;
  &-inner{
    text-align: center;
    display: table-cell;
    height: 100vh;
    width: 999999px;
    vertical-align: middle;
    padding: 200px 0;
    .bg{
      background-position: center;
    }
  }
  &-content{
    position: relative;
    z-index: 2;
  }
  &-nav{
    padding-bottom: 62px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 50%;
    &-inner{
      display: flex;
      align-items: flex-end;
    }
  }
  &-product-wrapper{
    padding-bottom: 62px;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 50%;
  }
  &-product{
    width: 240px;
    display: flex;
    align-items: center;
    margin-left: auto;
    .content{
      font-size: 14px;
      padding-right: 20px;
      text-align: right;
      color: #fff;
      width: 100%;
    }
    .name{
      display: inline-block;
      color: #fff;
      text-decoration: none;
      margin-bottom: 6px;
      cursor: pointer;
    }
    .price{
      margin-top: 4px;
    }
    .line{
      height: 1px;
      background-color: #fff;
      position: relative;
      &:after{
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: #fff;
      }
    }
    .image{
      position: relative;
      width: 60px;
      height: 60px;
      flex: 0 0 60px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  &-product-name{
    font-size: 12px;
    color: #ffffff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
    padding: 0 15px;
    text-align: center;
    display: none;
  }
}

/*==========  Hover  ==========*/

@media (min-width: 991px){
  .banner-product .name:hover{
    text-decoration: underline;
  }
}

/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px){}

@media (max-width: 1199px){
  .banner {
    &-inner {
      padding: 150px 0;
    }
    &-nav{
      padding-bottom: 20px;
      max-width: 100%;
      &-inner {
        justify-content: center;
      }
    }
    &-product {
      &-wrapper {
        display: none;
      }
      &-name{
        display: block;
      }
    }
  }
}

//@media (max-width: 991px){}

//@media (max-width: 768px){}

//@media (max-width: 575px){}




