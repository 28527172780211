/*##########*/
/* FONTS */
/*##########*/

@font-face {
  font-family: "Intro";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/Intro/intro-light/intro-light.eot");
  src: url("../fonts/Intro/intro-light/intro-light.eot?#iefix") format("embedded-opentype"), url("../fonts/Intro/intro-light/intro-light.woff") format("woff"), url("../fonts/Intro/intro-light/intro-light.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Intro";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/Intro/intro-regular/intro-regular.eot");
  src: url("../fonts/Intro/intro-regular/intro-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Intro/intro-regular/intro-regular.woff") format("woff"), url("../fonts/Intro/intro-regular/intro-regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Intro";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/Intro/intro-book3/intro-book.eot");
  src: url("../fonts/Intro/intro-book3/intro-book.eot?#iefix") format("embedded-opentype"), url("../fonts/Intro/intro-book3/intro-book.woff") format("woff"), url("../fonts/Intro/intro-book3/intro-book.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "OpticianSans";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/OpticianSans/OpticianSans.eot");
  src: url("../fonts/OpticianSans/OpticianSans.eot?#iefix") format("embedded-opentype"), url("../fonts/OpticianSans/OpticianSans.woff") format("woff"), url("../fonts/OpticianSans/OpticianSans.ttf") format("truetype");
  font-display: swap;
}
