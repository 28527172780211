/*==========  Form Style  ==========*/

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color: #222;
	opacity: 1;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
	color: #222;
	opacity: 1;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
	color: #222;
	opacity: 1;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	color: #222;
	opacity: 1;
}

input, textarea, select {
	border-radius: 0;
	background: none;
	border: none;
	margin: 0;
	width: 100%;
	padding: 0;
}

textarea {
	display: block;
	resize: none;
	overflow: auto;
}

select::-ms-expand {
	display: none;
}

.invalid {
	border-color: red;
	color: red;
}

/*==========  Text fields  ==========*/

.input-error{
	//display: none;
	margin-top: 5px;
	color: #FA665B;
	font-size: 14px;
}

.input {
	font-size: 16px;
	color: #06040A;
	height: 44px;
	padding: 0;
	border-bottom: 1px solid $color-dark-900;
	outline: none;
	transition: 0.24s;
	&::placeholder{
		font-size: 16px;
		font-weight: 300;
		color: $color-gray-400;
		transition: 0.24s;
	}
	&:focus{
		&::placeholder{
			color: #B0ACB1!important;
		}
	}
	&:disabled,
	&.dispbled{
		pointer-events: none;
		color: #AAAAAA;
	}
	&.error{
		box-shadow: 0px 0px 0px 1.5px $color-red;
		+ .input-error{
			display: block;
		}
	}
	&-inner{
		position: relative;
		.toggle{
			display: block;
			position: absolute;
			line-height: 0;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			.icon{
				display: block;
				width: 24px;
				line-height: 0;
				&.hide{
					display: none;
				}
			}
		}
		&.hide{
			.icon{
				&.show{
					display: none;
				}
				&.hide{
					display: block;
				}
			}
		}
		&.input-hidden-text{
			.input{
				padding-right: 30px;
			}
		}
		&:before{
			content: "";
			position: absolute;
			top: auto;
			bottom: 1px;
			left: 0;
			right: 100%;
			transition: right .5s linear;
			border-bottom: 1px solid #000;
			z-index: 2;
		}
		&:not(.no_underline):focus-within:before{
			right: 0;
		}
	}
}

textarea.input {
	padding-top: 15px;
	line-height: 18px;
	min-height: 44px;
	resize: vertical;
}

/*==========  Text fields (phone) ==========*/
.input-phone{
	.iti{
		&--allow-dropdown{
			width: 100%;
		}
		&--allow-dropdown .iti__flag-container{
			position: static;
		}
		&--allow-dropdown .iti__selected-flag{
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			background-color: rgba(1,1,1,0);
			padding: 0 4px 0 0;
			font-size: 16px;
			font-weight: 300;
			color: #767185;
			&:after{
				content: '';
				display: block;
				height: calc(100% - 14px);
				width: 1px;
				background-color: #06040A;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		&--allow-dropdown .iti__country-list{
			top: 100%;
			width: 100%;
			box-shadow: none;
			border: 1px solid rgba(1, 1, 1, 0.08);
			overflow-x: hidden;
		}
		&__selected-dial-code{
			margin-left: 0;
		}
		&__arrow{
			width: 24px;
			height: 24px;
			border: 0;
			margin-left: 4px;
			background-image: url('../img/icons/icon-arrow-03932.svg');
			background-position: center;
			background-size: 100%;
		}
		&__selected-flag{
			.iti__flag{
				display: none;
			}
		}
	}
}

/*==========  Text fields (datepicker) ==========*/
.input-datepicker{
	.datepicker-cell{
		&.selected{
			font-weight: 400;
			background-color: #B2A0BB;
		}
	}
	.datepicker-picker span{
		border-radius: 0;
	}
	.datepicker-dropdown{
		.datepicker-picker{
			border-radius: 0;
			box-shadow: none;
			border: 1px solid rgba(1, 1, 1, 0.08);
		}
	}
}

/*==========  Hover  ==========*/

@media (min-width: 991px){
	.input:hover{
		&::placeholder{
			color: $color-dark-900;
		}
	}
}

/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px)

// @media (max-width: 1199px){}

@media (max-width: 991px){
	.input{
		font-size: 14px;
	}
}

// @media (max-width: 768px)

// @media (max-width: 575px)
