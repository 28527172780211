$transition: all .34s ease-out;

$font1: 'Intro', sans-serif;
$font2: 'OpticianSans', sans-serif;

$color1: #B2A0BB;
$colorDark: #000000;

$color-dark-500: #B2A0BB;
$color-dark-600: #8768AD;
$color-dark-700: #724d9f;
$color-dark-800: #1C1642;
$color-dark-900: #06040A;

$color-gray-100: #ECEAEF;
$color-gray-200: #C8C3CE;
$color-gray-300: #B0ACB1;
$color-gray-400: #767185;

$color-lilac-100: #F9F8FB;
$color-lilac-200: #F5F4FA;
$color-lilac-300: #E8E2EF;
$color-lilac-400: #ECE7F2;
$color-lilac-500: #D4C9E1;

$color-neutral: #F6F3F2;
$color-green: #E5EEE0;
$color-black: #393939;
$color-beige: #FBF8F2;
$color-rose: #F9E0E3;
$color-red: #E26371;


