/*==========  GLOBAL  ==========*/
body {
	font-family: $font1;
	color: $color-dark-900;
}

b, strong {
	font-weight: 700;
}

a:hover {
	text-decoration: none;
}
a {
	text-decoration: none;
	color: inherit;
}

ul, ol {
	list-style: none;
}
