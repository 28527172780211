/*==========  TYPOGRAPHY  ==========*/
@import "global";
@import "size";
@import "simple-content";

/* Font family*/
.font-fm-01{
	font-family: $font1;
}
.font-fm-02{
	font-family: $font2;
}

/* Align */
.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

/* Text weight */
.fw-200 {
	font-weight: 200;
}
.fw-300 {
	font-weight: 300;
}
.fw-400 {
	font-weight: 400;
}
.fw-500 {
	font-weight: 500;
}
.fw-600 {
	font-weight: 600;
}
.fw-700 {
	font-weight: 700;
}

/* Text color */
.color-primary{
	color: #000000;
}
.color-secondary {
	color: $color1;
}
.color-white {
	color: #fff;
}
.color-dark {
	color: #111;
}

.color-grey-01{
	color: $color-gray-300;
}
.color-grey-02{
	color: $color-gray-400;
}

.color-dark-01{
	color: $color-dark-700;
}

.color-dark-02{
	color: $color-dark-500;
}

.color-dark-03{
	color: $color-gray-400;
}




.text-uppercase{
	text-transform: uppercase;
}

.font1{
	font-family: $font1;
}
.font2{
	font-family: $font2;
}

.ls-04{
	letter-spacing: 0.04em;
}


/*==========  Hover  ==========*/

// @media (min-width: 1200px){}

/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px)

@media (max-width: 1199px){
	.text-xl-center {
		text-align: center;
	}
	.text-xl-left{
		text-align: left;
	}
	.text-xl-right {
		text-align: right;
	}

	.fw-xl-400 {
		font-weight: 400;
	}
}

 @media (max-width: 991px){
	 .text-lg-center {
		 text-align: center;
	 }

	 .text-lg-right {
		 text-align: right;
	 }

	 .text-lg-left {
		 text-align: left;
	 }
 }

// @media (max-width: 768px){}

// @media (max-width: 575px)
