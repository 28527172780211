/*==========  SIMPLE CONTENT  ==========*/
.text{
	> * {
		margin-bottom: 26px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	img {
		max-width: 100%;
		height: auto;
		display: block;
	}
	ol {
		counter-reset: number;
		li {
			padding-left: 0;
			margin-bottom: 8px;
			position: relative;
			&:before {
				counter-increment: number;
				content: counter(number);
				margin-right: 8px;
				font-weight: 400;
				font-size: 14px;
				color: #111111;
			}
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	ul li {
		padding-left: 30px;
		margin-bottom: 5px;
		position: relative;
		&:before {
			content: "";
			display: inline-block;
			background-color: #111111;
			background-repeat: no-repeat;
			background-size: contain;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			vertical-align: middle;
			position: absolute;
			left: 12px;
			top: 0.7em;
		}
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	> iframe {
		width: 100%;
		height: 480px;
		max-height: calc(56.3vw - 30px);
	}

	b {
		font-weight: 400;
	}
	strong{
		font-weight: 500;
	}
	a{
		font-weight: 400;
		text-decoration: none;
		color: $color-dark-700;
		position: relative;
		padding-bottom: 4px;
		&:after{
			content: '';
			display: block;
			height: 1px;
			width: 0;
			position: absolute;
			left: 0;
			bottom: 0;
			transition: $transition;
			background-color: $color-dark-700;
		}
	}
}

/* Table */
.text table,
.table{
	width: 100%;
	border-left: 1px solid $color-lilac-400;
	border-top: 1px solid $color-lilac-400;
	border-spacing: 0;
	line-height: 1.4em;
	th,
	td {
		padding: 14px 16px;
		border-right: 1px solid $color-lilac-400;
		border-bottom: 1px solid $color-lilac-400;
	}
	tr{
		&:nth-child(even){
			th,
			td {
				background-color: $color-lilac-100;
			}
		}
	}
}
.text blockquote,
.blockquote {
	padding: 40px 150px 20px 150px;
	position: relative;
	min-height: 150px;
	&:after{
		content: '';
		display: block;
		width: 96px;
		height: 96px;
		background-image: url('../../img/decor-00-03.svg');
		background-size: contain;
		position: absolute;
		top: 0;
		left: 0;
	}
	&:before{
		content: '';
		display: block;
		width: 96px;
		height: 96px;
		background-image: url('../../img/decor-00-04.svg');
		background-size: contain;
		position: absolute;
		bottom: 0;
		right: 0;
	}
}

/*==========  Hover  ==========*/

@media (min-width: 991px){
	.text a:hover{
		&:after{
			width: 100%;
		}
	}
}

/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px)

// @media (max-width: 1199px){}

@media (max-width: 991px){
	.text {
		> * {
			margin-bottom: 15px;
		}
		ul {
			li {
				padding-left: 25px;
				&:before {
					left: 10px;
					top: 0.6em;
				}
			}
		}
	}
	.table{
		th,
		td {
			padding: 8px;
		}
	}
	.blockquote{
		padding: 0;
		&:after,
		&:before{
			display: none;
		}
	}
}

// @media (max-width: 768px){}

// @media (max-width: 575px)
