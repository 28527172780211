/*==========  Swiper Style  ==========*/
.swiper-entry {
  position: relative;
}

.swiper-container {
  z-index: auto;
}
/*
.swiper-container:not(.swiper-no-swiping) .swiper-wrapper {
  cursor: url(../img/drag.png) 16 9, ew-resize;
}
.swiper-container-vertical:not(.swiper-no-swiping) .swiper-wrapper {
  cursor: url(../img/drag-vertical.png) 8 16, ns-resize;
}
*/

.swiper-entry{
  &.swiper-style1{
    .swiper-pagination {
      position: relative;
      line-height: 0;
    }
    .swiper-pagination-bullet {
      background-color: #808284;
      opacity: 1;
      margin-right: 10px;
      transition: $transition;

      &.swiper-pagination-bullet-active {
        background-color: #ffffff;
      }
    }
  }
  &.swiper-style2{
    .swiper-pagination {
      line-height: 0;
      bottom: 10px;
      width: 100%;
      padding: 10px 15px;
    }
    .swiper-pagination-bullet {
      background: rgba(255, 255, 255, 0.4);
      opacity: 1;
      margin-right: 10px;
      transition: $transition;

      &.swiper-pagination-bullet-active {
        background-color: #ffffff;
      }
    }
  }
  &.inst-detail-popup{
    height: 100%;
    max-height: calc(100vh - 30px);
    position: sticky;
    top: 0;
    .swiper-container,
    .swiper-wrapper,
    .swiper-slide{
      height: 100%;
    }
  }

  &.capsule-detail-popup{
    height: 100%;
    .swiper-container,
    .swiper-wrapper,
    .swiper-slide{
      height: 100%;
    }
  }
  &.swiper-style3{
    .swiper-button-prev,
    .swiper-button-next{
      width: 36px;
      height: 36px;
      border-radius: 2px;
      background-color: rgba(255, 255, 255, 0.4);
      opacity: 1!important;
      transition: $transition;
    }
    .swiper-button-next{
      background-image: url('../img/icons/icon-arrow-right.svg');
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: center;
    }
    .swiper-button-prev{
      background-image: url('../img/icons/icon-arrow-left.svg');
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: center;
    }
    .swiper-pagination {
      line-height: 0;
      bottom: 10px;
      width: 100%;
      padding: 10px 15px;
    }
    .swiper-pagination-bullet {
      background-color: rgba(255, 255, 255, 0.4);
      opacity: 1;
      margin-right: 10px;
      transition: $transition;

      &.swiper-pagination-bullet-active {
        background-color: #ffffff;
      }
    }
  }
  &.swiper-style4{
    .swiper-slide img {
      width: 100%;
    }
    .swiper-container-3d{
      .swiper-slide-shadow-left,
      .swiper-slide-shadow-right{
        display: none;
      }
    }
  }
  &.swiper-style5{
    width: calc(100% + 15px);
    .swiper-container{
      padding-right: 20px;
    }
  }
}

@media (min-width: 991px) {
  .swiper-entry{
    &.swiper-style4{
      .swiper-slide {
        text-align: center;
        width: 25%!important;
        border: 1px solid rgba(1,1,1,0.06);
        line-height: 0;
      }
      .swiper-slide img {
        width: 100%;
      }
    }
  }
}

/*==========  Hover  ==========*/

@media (min-width: 991px){
  .swiper-entry.swiper-style3 .swiper-button-prev:hover,
  .swiper-entry.swiper-style3 .swiper-button-next:hover{
    background-color: rgba(255, 255, 255, 0.6);
  }
}

/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px){}

@media (max-width: 1199px){
  .swiper-entry {
    &.swiper-style1 {
      .swiper-pagination-bullet {
        background-color: rgba(255, 255, 255, 0.4);
        margin-right: 5px;
        margin-left: 5px;
      }
    }
    &.swiper-style3{
      .swiper-button-prev,
      .swiper-button-next{
        width: 24px;
        height: 24px;
        background-size: 16px;
      }
    }
  }
}

//@media (max-width: 991px){}

//@media (max-width: 768px){}

//@media (max-width: 575px){}




