/*==========  Checkbox Style  ==========*/
.checkbox-entry {
  display: inline-block;
  cursor: pointer;
  margin: 0;
  position: relative;
  padding-left: 34px;
  line-height: 1.4em;
  transition: all .34s ease-out;
  &.checkbox-block{
    display: block;
    width: 100%;
  }
  input {
    display: none;
  }
  span {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: 1px solid #B2A0BB;
    border-radius: 1px;
    background-color: #fff;
    transition: $transition;
  }
  span:after {
    content: "";
    position: absolute;
    left: 6px;
    top: 42%;
    transform: translateY(-50%) rotate(45deg);
    width: 7px;
    height: 11px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    border-right: 2px solid #E8E2EF;
    border-bottom: 2px solid #E8E2EF;
    transition: $transition;
  }
  input:checked + span {
    border-color: #8768AD;
  }
  input:checked + span:after {
    opacity: 1;
    visibility: visible;
    border-color: #8768AD!important;
  }
  &.radio{
    padding-left: 28px;
  }
  input[type=radio] + span{
    width: 18px;
    height: 18px;
  }
  input[type=radio] + span:after{
    width: 10px;
    height: 10px;
    border: none;
    top: 50%;
    left: 3px;
    background-color: $color-lilac-300;
  }
  input[type=radio] + span,
  input[type=radio] + span:after{
    border-radius: 50%;
  }
  input[type=radio]:checked + span:after {
    opacity: 1;
    visibility: visible;
    background-color: #8768AD!important;
  }
  &.disable{
    pointer-events: none;
    span:before{
      background-color: #F0F0F0;
    }
  }
  &.disable-checked{
    pointer-events: none;
    input:checked + span:after{
      border-color: #AAAAAA;
    }
    input:checked + span:before {
      border-color: #F0F0F0;
      background-color: rgba(1,1,1,0);
    }
    input[type=radio] + span:after{
      background-color: #F0F0F0;
    }
  }
}

.checkbox-entry2{
  display: block;
  position: relative;
  padding: 4px 20px;
  cursor: pointer;
  transition: $transition;
  input {
    display: none;
  }
  span.layer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .icon{
    display: inline-block;
    width: 12px;
    line-height: 0;
    margin-left: 8px;
    img{
      width: 100%;
    }
  }
  span{
    vertical-align: middle;
  }
  input:checked + span.layer {
    background-color: #F5F4FA;
  }
}

.checkbox-wrapper{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -15px;
  .label{
    margin-bottom: 15px;
    margin-right: 15px;
  }
  .checkbox-entry{
    display: block;
    margin-bottom: 15px;
    margin-right: 15px;
  }
  &.center{
    justify-content: center;
  }
}
.checkbox-entry-wrapper{
  .error-text{
    display: none;
    margin-top: 4px;
    color: #FA665B;
    font-size: 14px;
    line-height: 0.9em;
  }
  &.error{
    .error-text{
      display: block;
    }
  }
}
/*==========  HOVER  ==========*/

@media (min-width: 992px){
  .checkbox-entry:hover{
    span{
      border-color: #8768AD;
      &:after{
        opacity: 1;
        visibility: visible;
        border-color: #E8E2EF;
      }
    }
  }
  .checkbox-entry2:hover{
    background-color: #F5F4FA;
  }
}

/*==========  MEDIA QUERIES  ==========*/
@media (max-width: 991px) {
  .checkbox-wrapper .label{
    flex: 0 0 100%;
    margin-right: 0;
  }
}

