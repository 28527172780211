/*-------------------------------------------------------------------------------------------------------------------------------*/
/* Spacers */
/*-------------------------------------------------------------------------------------------------------------------------------*/

.sp-pt{
	&-15{
		padding-top: 15px;
	}
	&-20{
		padding-top: 20px;
	}
	&-30{
		padding-top: 30px;
	}
	&-50{
		padding-top: 50px;
	}
	&-60{
		padding-top: 60px;
	}
	&-100{
		padding-top: 100px;
	}
	&-120{
		padding-top: 120px;
	}
	&-260{
		padding-top: 260px;
	}
}
.sp-pb{
	&-20{
		padding-bottom: 20px;
	}
	&-40{
		padding-bottom: 40px;
	}
	&-60{
		padding-bottom: 60px;
	}
	&-100{
		padding-bottom: 100px;
	}
	&-120{
		padding-bottom: 120px;
	}
	&-140{
		padding-bottom: 140px;
	}
	&-180{
		padding-bottom: 180px;
	}
	&-260{
		padding-bottom: 260px;
	}
}
.sp-mt{
	&-20{
		margin-top: 20px;
	}
	&-60{
		margin-top: 60px;
	}
	&-100{
		margin-top: 100px;
	}
	&-120{
		margin-top: 120px;
	}
}
.sp-mb{
	&-5{
		margin-bottom: 5px;
	}
	&-8{
		margin-bottom: 8px;
	}
	&-10{
		margin-bottom: 10px;
	}
	&-15{
		margin-bottom: 15px;
	}
	&-20{
		margin-bottom: 20px;
	}
	&-25{
		margin-bottom: 25px;
	}
	&-30{
		margin-bottom: 30px;
	}
	&-40{
		margin-bottom: 40px;
	}
	&-60{
		margin-bottom: 60px;
	}
	&-70{
		margin-bottom: 70px;
	}
	&-100{
		margin-bottom: 100px;
	}
	&-120{
		margin-bottom: 120px;
	}
	&-180{
		margin-bottom: 180px;
	}
}

/*==========  MEDIA QUERIES  ==========*/

@media (max-width: 1600px){
	.sp-mb-extra{
		&-20{
			margin-bottom: 20px;
		}
		&-30{
			margin-bottom: 30px;
		}
	}
	.sp-pt-extra {
		&-60 {
			padding-top: 60px;
		}
		&-80 {
			padding-top: 80px;
		}
	}
}

@media (max-width: 1199px){
	.sp-pt-xl{
		&-0{
			padding-top: 0px;
		}
		&-20{
			padding-top: 20px;
		}
		&-50{
			padding-top: 50px;
		}
		&-60{
			padding-top: 60px;
		}
		&-80{
			padding-top: 80px;
		}
		&-100{
			padding-top: 100px;
		}
		&-120{
			padding-top: 120px;
		}
	}
	.sp-pb-xl{
		&-0{
			padding-bottom: 0px;
		}
		&-20{
			padding-bottom: 20px;
		}
		&-40{
			padding-bottom: 40px;
		}
		&-60{
			padding-bottom: 60px;
		}
		&-80{
			padding-bottom: 80px;
		}
		&-100{
			padding-bottom: 100px;
		}
		&-120{
			padding-bottom: 120px;
		}
	}
	.sp-mt-xl{
		&-20{
			margin-top: 20px;
		}
		&-60{
			margin-top: 60px;
		}
		&-100{
			margin-top: 100px;
		}
		&-120{
			margin-top: 120px;
		}
	}
	.sp-mb-xl{
		&-0{
			margin-bottom: 0px;
		}
		&-5{
			margin-bottom: 5px;
		}
		&-10{
			margin-bottom: 10px;
		}
		&-15{
			margin-bottom: 15px;
		}
		&-20{
			margin-bottom: 20px;
		}
		&-30{
			margin-bottom: 30px;
		}
		&-40{
			margin-bottom: 40px;
		}
		&-50{
			margin-bottom: 50px;
		}
		&-60{
			margin-bottom: 60px;
		}
		&-100{
			margin-bottom: 100px;
		}
		&-120{
			margin-bottom: 120px;
		}
	}
}

@media (max-width: 991px){
	.sp-pt-lg{
		&-0{
			padding-top: 0px;
		}
		&-20{
			padding-top: 20px;
		}
		&-30{
			padding-top: 30px;
		}
		&-50{
			padding-top: 50px;
		}
		&-60{
			padding-top: 60px;
		}
		&-100{
			padding-top: 100px;
		}
		&-120{
			padding-top: 120px;
		}
	}
	.sp-pb-lg{
		&-20{
			padding-bottom: 20px;
		}
		&-50{
			padding-bottom: 50px;
		}
		&-60{
			padding-bottom: 60px;
		}
		&-100{
			padding-bottom: 100px;
		}
		&-120{
			padding-bottom: 120px;
		}
	}
	.sp-mt-lg{
		&-20{
			margin-top: 20px;
		}
		&-60{
			margin-top: 60px;
		}
		&-100{
			margin-top: 100px;
		}
		&-120{
			margin-top: 120px;
		}
	}
	.sp-mb-lg{
		&-0{
			margin-bottom: 0px;
		}
		&-20{
			margin-bottom: 20px;
		}
		&-40{
			margin-bottom: 40px;
		}
		&-50{
			margin-bottom: 50px;
		}
		&-60{
			margin-bottom: 60px;
		}
		&-100{
			margin-bottom: 100px;
		}
		&-120{
			margin-bottom: 120px;
		}
	}
}

@media (max-width: 768px){
	.sp-pt-md{
		&-20{
			padding-top: 20px;
		}
		&-60{
			padding-top: 60px;
		}
		&-100{
			padding-top: 100px;
		}
		&-120{
			padding-top: 120px;
		}
	}
	.sp-pb-md{
		&-20{
			padding-bottom: 20px;
		}
		&-60{
			padding-bottom: 60px;
		}
		&-100{
			padding-bottom: 100px;
		}
		&-120{
			padding-bottom: 120px;
		}
	}
	.sp-mt-md{
		&-20{
			margin-top: 20px;
		}
		&-60{
			margin-top: 60px;
		}
		&-100{
			margin-top: 100px;
		}
		&-120{
			margin-top: 120px;
		}
	}
	.sp-mb-md{
		&-20{
			margin-bottom: 20px;
		}
		&-60{
			margin-bottom: 60px;
		}
		&-100{
			margin-bottom: 100px;
		}
		&-120{
			margin-bottom: 120px;
		}
	}
}

@media (max-width: 575px){
	.sp-pt-sm{
		&-20{
			padding-top: 20px;
		}
		&-60{
			padding-top: 60px;
		}
		&-100{
			padding-top: 100px;
		}
		&-120{
			padding-top: 120px;
		}
	}
	.sp-pb-sm{
		&-20{
			padding-bottom: 20px;
		}
		&-60{
			padding-bottom: 60px;
		}
		&-100{
			padding-bottom: 100px;
		}
		&-120{
			padding-bottom: 120px;
		}
	}
	.sp-mt-sm{
		&-20{
			margin-top: 20px;
		}
		&-60{
			margin-top: 60px;
		}
		&-100{
			margin-top: 100px;
		}
		&-120{
			margin-top: 120px;
		}
	}
	.sp-mb-sm{
		&-20{
			margin-bottom: 20px;
		}
		&-60{
			margin-bottom: 60px;
		}
		&-100{
			margin-bottom: 100px;
		}
		&-120{
			margin-bottom: 120px;
		}
	}
}


