/*##########*/
/* FONTS */
/*##########*/
@font-face {
  font-family: "Intro";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/Intro/intro-light/intro-light.eot");
  src: url("../fonts/Intro/intro-light/intro-light.eot?#iefix") format("embedded-opentype"), url("../fonts/Intro/intro-light/intro-light.woff") format("woff"), url("../fonts/Intro/intro-light/intro-light.ttf") format("truetype");
  font-display: swap; }

@font-face {
  font-family: "Intro";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/Intro/intro-regular/intro-regular.eot");
  src: url("../fonts/Intro/intro-regular/intro-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Intro/intro-regular/intro-regular.woff") format("woff"), url("../fonts/Intro/intro-regular/intro-regular.ttf") format("truetype");
  font-display: swap; }

@font-face {
  font-family: "Intro";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/Intro/intro-book3/intro-book.eot");
  src: url("../fonts/Intro/intro-book3/intro-book.eot?#iefix") format("embedded-opentype"), url("../fonts/Intro/intro-book3/intro-book.woff") format("woff"), url("../fonts/Intro/intro-book3/intro-book.ttf") format("truetype");
  font-display: swap; }

@font-face {
  font-family: "OpticianSans";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/OpticianSans/OpticianSans.eot");
  src: url("../fonts/OpticianSans/OpticianSans.eot?#iefix") format("embedded-opentype"), url("../fonts/OpticianSans/OpticianSans.woff") format("woff"), url("../fonts/OpticianSans/OpticianSans.ttf") format("truetype");
  font-display: swap; }

/*==========  RESET STYLES  ==========*/
html {
  overflow-y: scroll; }

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-text-size-adjust: none;
  box-sizing: border-box; }

html:not(.touch-screen)::-webkit-scrollbar,
html:not(.touch-screen) *::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

html:not(.touch-screen)::-webkit-scrollbar-track,
html:not(.touch-screen) *::-webkit-scrollbar-track {
  background: #fff; }

html:not(.touch-screen)::-webkit-scrollbar-thumb,
html:not(.touch-screen) *::-webkit-scrollbar-thumb {
  background: #D4C9E1; }

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* GLOBAL SETTINGS */
/*-------------------------------------------------------------------------------------------------------------------------------*/
html {
  height: 100%; }

body {
  height: 100%; }

#content-block {
  position: relative;
  min-height: 100vh;
  background: #FCFCFE; }
  #content-block.not-main-page {
    padding-top: 100px; }

.overflow-hidden {
  overflow: hidden; }

html.overflow-hidden body {
  overflow: hidden; }

/*==========  MEDIA QUERIES  ==========*/
@media (max-width: 1600px) {
  #content-block.not-main-page {
    padding-top: 80px; } }

@media (max-width: 1199px) {
  #content-block {
    overflow: hidden; }
    #content-block.not-main-page {
      padding-top: 60px; } }

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* ANIMATIONS */
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* Fade-in */
.fade-in {
  transition: all 0.4s ease-out;
  opacity: 0; }
  .fade-in-left {
    transition: all 0.8s ease-out;
    opacity: 0;
    transform: translate3d(15px, 0, 0); }

.fade-in.animated,
.fade-in-left.animated {
  transform: none;
  opacity: 1; }

.animation.animated .fade-in,
.animation.animated .fade-in-left {
  transform: none;
  opacity: 1; }

/* Slide */
.bg-anim {
  background-color: #FCFCFE;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  z-index: 9; }

.anim-slide-bottom {
  transition-duration: 1s; }
  .anim-slide-bottom.dur-1_5 {
    transition-duration: 2s; }
  .anim-slide-bottom.animated {
    height: 0; }

.anim-slide-right {
  transition-duration: 1s; }
  .anim-slide-right.animated {
    width: 0; }

.animation.animated .anim-slide-bottom {
  height: 0; }

.animation.animated .anim-slide-right {
  width: 0; }

/* Delay */
.delay-01 {
  transition-delay: .3s; }

.delay-02 {
  transition-delay: .6s; }

.delay-03 {
  transition-delay: .9s; }

.delay-04 {
  transition-delay: 1.2s; }

.delay-05 {
  transition-delay: 1.5s; }

.delay-06 {
  transition-delay: 1.8s; }

.delay-07 {
  transition-delay: 2.1s; }

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* Spacers */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.sp-pt-15 {
  padding-top: 15px; }

.sp-pt-20 {
  padding-top: 20px; }

.sp-pt-30 {
  padding-top: 30px; }

.sp-pt-50 {
  padding-top: 50px; }

.sp-pt-60 {
  padding-top: 60px; }

.sp-pt-100 {
  padding-top: 100px; }

.sp-pt-120 {
  padding-top: 120px; }

.sp-pt-260 {
  padding-top: 260px; }

.sp-pb-20 {
  padding-bottom: 20px; }

.sp-pb-40 {
  padding-bottom: 40px; }

.sp-pb-60 {
  padding-bottom: 60px; }

.sp-pb-100 {
  padding-bottom: 100px; }

.sp-pb-120 {
  padding-bottom: 120px; }

.sp-pb-140 {
  padding-bottom: 140px; }

.sp-pb-180 {
  padding-bottom: 180px; }

.sp-pb-260 {
  padding-bottom: 260px; }

.sp-mt-20 {
  margin-top: 20px; }

.sp-mt-60 {
  margin-top: 60px; }

.sp-mt-100 {
  margin-top: 100px; }

.sp-mt-120 {
  margin-top: 120px; }

.sp-mb-5 {
  margin-bottom: 5px; }

.sp-mb-8 {
  margin-bottom: 8px; }

.sp-mb-10 {
  margin-bottom: 10px; }

.sp-mb-15 {
  margin-bottom: 15px; }

.sp-mb-20 {
  margin-bottom: 20px; }

.sp-mb-25 {
  margin-bottom: 25px; }

.sp-mb-30 {
  margin-bottom: 30px; }

.sp-mb-40 {
  margin-bottom: 40px; }

.sp-mb-60 {
  margin-bottom: 60px; }

.sp-mb-70 {
  margin-bottom: 70px; }

.sp-mb-100 {
  margin-bottom: 100px; }

.sp-mb-120 {
  margin-bottom: 120px; }

.sp-mb-180 {
  margin-bottom: 180px; }

/*==========  MEDIA QUERIES  ==========*/
@media (max-width: 1600px) {
  .sp-mb-extra-20 {
    margin-bottom: 20px; }
  .sp-mb-extra-30 {
    margin-bottom: 30px; }
  .sp-pt-extra-60 {
    padding-top: 60px; }
  .sp-pt-extra-80 {
    padding-top: 80px; } }

@media (max-width: 1199px) {
  .sp-pt-xl-0 {
    padding-top: 0px; }
  .sp-pt-xl-20 {
    padding-top: 20px; }
  .sp-pt-xl-50 {
    padding-top: 50px; }
  .sp-pt-xl-60 {
    padding-top: 60px; }
  .sp-pt-xl-80 {
    padding-top: 80px; }
  .sp-pt-xl-100 {
    padding-top: 100px; }
  .sp-pt-xl-120 {
    padding-top: 120px; }
  .sp-pb-xl-0 {
    padding-bottom: 0px; }
  .sp-pb-xl-20 {
    padding-bottom: 20px; }
  .sp-pb-xl-40 {
    padding-bottom: 40px; }
  .sp-pb-xl-60 {
    padding-bottom: 60px; }
  .sp-pb-xl-80 {
    padding-bottom: 80px; }
  .sp-pb-xl-100 {
    padding-bottom: 100px; }
  .sp-pb-xl-120 {
    padding-bottom: 120px; }
  .sp-mt-xl-20 {
    margin-top: 20px; }
  .sp-mt-xl-60 {
    margin-top: 60px; }
  .sp-mt-xl-100 {
    margin-top: 100px; }
  .sp-mt-xl-120 {
    margin-top: 120px; }
  .sp-mb-xl-0 {
    margin-bottom: 0px; }
  .sp-mb-xl-5 {
    margin-bottom: 5px; }
  .sp-mb-xl-10 {
    margin-bottom: 10px; }
  .sp-mb-xl-15 {
    margin-bottom: 15px; }
  .sp-mb-xl-20 {
    margin-bottom: 20px; }
  .sp-mb-xl-30 {
    margin-bottom: 30px; }
  .sp-mb-xl-40 {
    margin-bottom: 40px; }
  .sp-mb-xl-50 {
    margin-bottom: 50px; }
  .sp-mb-xl-60 {
    margin-bottom: 60px; }
  .sp-mb-xl-100 {
    margin-bottom: 100px; }
  .sp-mb-xl-120 {
    margin-bottom: 120px; } }

@media (max-width: 991px) {
  .sp-pt-lg-0 {
    padding-top: 0px; }
  .sp-pt-lg-20 {
    padding-top: 20px; }
  .sp-pt-lg-30 {
    padding-top: 30px; }
  .sp-pt-lg-50 {
    padding-top: 50px; }
  .sp-pt-lg-60 {
    padding-top: 60px; }
  .sp-pt-lg-100 {
    padding-top: 100px; }
  .sp-pt-lg-120 {
    padding-top: 120px; }
  .sp-pb-lg-20 {
    padding-bottom: 20px; }
  .sp-pb-lg-50 {
    padding-bottom: 50px; }
  .sp-pb-lg-60 {
    padding-bottom: 60px; }
  .sp-pb-lg-100 {
    padding-bottom: 100px; }
  .sp-pb-lg-120 {
    padding-bottom: 120px; }
  .sp-mt-lg-20 {
    margin-top: 20px; }
  .sp-mt-lg-60 {
    margin-top: 60px; }
  .sp-mt-lg-100 {
    margin-top: 100px; }
  .sp-mt-lg-120 {
    margin-top: 120px; }
  .sp-mb-lg-0 {
    margin-bottom: 0px; }
  .sp-mb-lg-20 {
    margin-bottom: 20px; }
  .sp-mb-lg-40 {
    margin-bottom: 40px; }
  .sp-mb-lg-50 {
    margin-bottom: 50px; }
  .sp-mb-lg-60 {
    margin-bottom: 60px; }
  .sp-mb-lg-100 {
    margin-bottom: 100px; }
  .sp-mb-lg-120 {
    margin-bottom: 120px; } }

@media (max-width: 768px) {
  .sp-pt-md-20 {
    padding-top: 20px; }
  .sp-pt-md-60 {
    padding-top: 60px; }
  .sp-pt-md-100 {
    padding-top: 100px; }
  .sp-pt-md-120 {
    padding-top: 120px; }
  .sp-pb-md-20 {
    padding-bottom: 20px; }
  .sp-pb-md-60 {
    padding-bottom: 60px; }
  .sp-pb-md-100 {
    padding-bottom: 100px; }
  .sp-pb-md-120 {
    padding-bottom: 120px; }
  .sp-mt-md-20 {
    margin-top: 20px; }
  .sp-mt-md-60 {
    margin-top: 60px; }
  .sp-mt-md-100 {
    margin-top: 100px; }
  .sp-mt-md-120 {
    margin-top: 120px; }
  .sp-mb-md-20 {
    margin-bottom: 20px; }
  .sp-mb-md-60 {
    margin-bottom: 60px; }
  .sp-mb-md-100 {
    margin-bottom: 100px; }
  .sp-mb-md-120 {
    margin-bottom: 120px; } }

@media (max-width: 575px) {
  .sp-pt-sm-20 {
    padding-top: 20px; }
  .sp-pt-sm-60 {
    padding-top: 60px; }
  .sp-pt-sm-100 {
    padding-top: 100px; }
  .sp-pt-sm-120 {
    padding-top: 120px; }
  .sp-pb-sm-20 {
    padding-bottom: 20px; }
  .sp-pb-sm-60 {
    padding-bottom: 60px; }
  .sp-pb-sm-100 {
    padding-bottom: 100px; }
  .sp-pb-sm-120 {
    padding-bottom: 120px; }
  .sp-mt-sm-20 {
    margin-top: 20px; }
  .sp-mt-sm-60 {
    margin-top: 60px; }
  .sp-mt-sm-100 {
    margin-top: 100px; }
  .sp-mt-sm-120 {
    margin-top: 120px; }
  .sp-mb-sm-20 {
    margin-bottom: 20px; }
  .sp-mb-sm-60 {
    margin-bottom: 60px; }
  .sp-mb-sm-100 {
    margin-bottom: 100px; }
  .sp-mb-sm-120 {
    margin-bottom: 120px; } }

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* Grid */
/*-------------------------------------------------------------------------------------------------------------------------------*/
@media (min-width: 1600px) {
  .col-extra {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-extra-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-extra-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-extra-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-extra-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-extra-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-extra-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-extra-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-extra-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-extra-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-extra-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-extra-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-extra-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-extra-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-extra-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-extra-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-extra-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-extra-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-extra-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-extra-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-extra-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-extra-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-extra-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-extra-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-extra-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-extra-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-extra-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-extra-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-extra-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-extra-0 {
    margin-left: 0; }
  .offset-extra-1 {
    margin-left: 8.333333%; }
  .offset-extra-2 {
    margin-left: 16.666667%; }
  .offset-extra-3 {
    margin-left: 25%; }
  .offset-extra-4 {
    margin-left: 33.333333%; }
  .offset-extra-5 {
    margin-left: 41.666667%; }
  .offset-extra-6 {
    margin-left: 50%; }
  .offset-extra-7 {
    margin-left: 58.333333%; }
  .offset-extra-8 {
    margin-left: 66.666667%; }
  .offset-extra-9 {
    margin-left: 75%; }
  .offset-extra-10 {
    margin-left: 83.333333%; }
  .offset-extra-11 {
    margin-left: 91.666667%; } }

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* Section settings */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.section {
  position: relative;
  z-index: 0; }
  .section.z-auto {
    z-index: auto; }

.container-cs {
  padding-right: 75px;
  padding-left: 75px;
  margin-right: auto;
  margin-left: auto; }
  .container-cs.md {
    max-width: 1010px; }
  .container-cs.xl {
    max-width: 1360px; }
  .container-cs.xl-extra {
    max-width: 1610px; }

.row.row-0 {
  margin-right: 0px;
  margin-left: 0px; }
  .row.row-0 > [class*=col] {
    padding-right: 0px;
    padding-left: 0px; }

.row.row-1 {
  margin-right: -1px;
  margin-left: -1px; }
  .row.row-1 > [class*=col] {
    padding-right: 1px;
    padding-left: 1px; }

.row.row-5 {
  margin-right: -5px;
  margin-left: -5px; }
  .row.row-5 > [class*=col] {
    padding-right: 5px;
    padding-left: 5px; }

.row.row-20 {
  margin-right: -10px;
  margin-left: -10px; }
  .row.row-20 > [class*=col] {
    padding-right: 10px;
    padding-left: 10px; }

.row.row-40 {
  margin-right: -20px;
  margin-left: -20px; }
  .row.row-40 > [class*=col] {
    padding-right: 20px;
    padding-left: 20px; }

.row.row-50 {
  margin-right: -25px;
  margin-left: -25px; }
  .row.row-50 > [class*=col] {
    padding-right: 25px;
    padding-left: 25px; }

.row.row-70 {
  margin-right: -35px;
  margin-left: -35px; }
  .row.row-70 > [class*=col] {
    padding-right: 35px;
    padding-left: 35px; }

.row.row-100 {
  margin-right: -50px;
  margin-left: -50px; }
  .row.row-100 > [class*=col] {
    padding-right: 50px;
    padding-left: 50px; }

.row.row-120 {
  margin-right: -60px;
  margin-left: -60px; }
  .row.row-120 > [class*=col] {
    padding-right: 60px;
    padding-left: 60px; }

.row.row-180 {
  margin-right: -90px;
  margin-left: -90px; }
  .row.row-180 > [class*=col] {
    padding-right: 90px;
    padding-left: 90px; }

.wrapper-grid-30 {
  margin-bottom: -30px; }
  .wrapper-grid-30 [class*=col-] {
    margin-bottom: 30px; }

/*==========  MEDIA QUERIES  ==========*/
@media (max-width: 1600px) {
  .container-cs {
    padding-right: 50px;
    padding-left: 50px; }
  .row.row-xl-4 {
    margin-right: -2px;
    margin-left: -2px; }
    .row.row-xl-4 > [class*=col] {
      padding-right: 2px;
      padding-left: 2px; }
  .row.row-xl-10 {
    margin-right: -5px;
    margin-left: -5px; }
    .row.row-xl-10 > [class*=col] {
      padding-right: 5px;
      padding-left: 5px; }
  .row.row-xl-16 {
    margin-right: -8px;
    margin-left: -8px; }
    .row.row-xl-16 > [class*=col] {
      padding-right: 8px;
      padding-left: 8px; }
  .row.row-xl-30 {
    margin-right: -15px;
    margin-left: -15px; }
    .row.row-xl-30 > [class*=col] {
      padding-right: 15px;
      padding-left: 15px; } }

@media (max-width: 1368px) {
  .container-cs {
    padding-right: 30px;
    padding-left: 30px; } }

@media (max-width: 1199px) {
  .container-cs {
    padding-right: 15px;
    padding-left: 15px; } }

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* OTHER ELEMENTS */
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* BG */
.video-bg:after,
.bg:after {
  content: '';
  display: block; }

.video-bg,
.video-bg:after,
.wr-hd,
.bg,
.bg:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.bg {
  background-size: cover;
  background-repeat: no-repeat; }

.wr-hd {
  overflow: hidden;
  z-index: -1; }

.video-bg video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%) scale(1.1); }

/* Icon arrow */
.icon-arrow {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px; }
  .icon-arrow:before, .icon-arrow:after {
    content: "";
    position: absolute;
    top: 0;
    width: 1px;
    height: 9px;
    background-color: #111111; }
  .icon-arrow:before {
    left: 0;
    transform: rotate(-45deg); }
  .icon-arrow:after {
    right: 0;
    transform: rotate(45deg); }

/* Main loader */
#loader-wrapper {
  position: fixed;
  left: 0;
  top: -100px;
  right: 0;
  bottom: -100px;
  background: #fff;
  z-index: 2000; }

.image-wrapper {
  line-height: 0; }
  .image-wrapper img {
    max-width: 100%; }
  .image-wrapper.right {
    text-align: right; }
  .image-wrapper.center {
    text-align: center; }
  .image-wrapper.ps-01 {
    display: inline-block;
    transform: translateX(50%);
    margin-top: -250px; }

.video-wrapper {
  line-height: 0; }
  .video-wrapper video {
    width: 100%; }

.ps-relative {
  position: relative; }

.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .title-wrapper .text-in {
    margin-right: 10px; }

.hr {
  height: 1px;
  background-color: #E8E2EF; }

.separate-hr {
  height: 1px;
  background-color: #E8E2EF; }

.separate-or {
  text-align: center;
  position: relative;
  font-size: 14px;
  font-weight: 300;
  color: #767185; }
  .separate-or:after, .separate-or:before {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    background-color: #E8E2EF;
    width: calc(50% - 40px); }
  .separate-or:before {
    top: 50%;
    left: 0; }
  .separate-or:after {
    top: 50%;
    right: 0; }

.img-container {
  max-width: 96px;
  line-height: 0; }
  .img-container img {
    max-width: 100%; }
  .img-container.size-850 {
    max-width: 850px; }
  .img-container.size-200 {
    max-width: 200px; }
  .img-container.center {
    text-align: center;
    margin-left: auto;
    margin-right: auto; }

.img-bg-container {
  position: relative;
  padding-bottom: 100%; }
  .img-bg-container.size-120 {
    padding-bottom: 120%; }

.wrapper-scroll {
  overflow-y: auto;
  overflow-x: hidden; }
  .wrapper-scroll.mx-1000 {
    max-height: 1100px; }

.overflow-hidden {
  overflow: hidden; }

.text-with-icon .icon {
  display: inline-block;
  width: 20px;
  line-height: 0;
  margin-right: 10px; }
  .text-with-icon .icon img {
    width: 100%; }

.text-with-icon span {
  vertical-align: middle; }

.d-none {
  display: none !important; }

.d-block {
  display: block !important; }

/*==========  Hover  ==========*/
/*==========  MEDIA QUERIES  ==========*/
@media (max-width: 1600px) {
  .image-wrapper.ps-01 {
    margin-top: -50px; } }

@media (max-width: 1199px) {
  .image-wrapper.xl-center {
    text-align: center; }
  .title-wrapper:not(.not-mob) {
    display: block; }
    .title-wrapper:not(.not-mob) .text-in {
      margin-right: 0; }
  .wrapper-scroll.mx-unset-xl {
    max-height: unset; }
  .d-none-xl {
    display: none !important; }
  .d-block-xl {
    display: block !important; } }

@media (max-width: 991px) {
  .image-wrapper.lg-center {
    text-align: center; }
  .d-none-lg {
    display: none !important; }
  .d-block-lg {
    display: block !important; } }

/*==========  TYPOGRAPHY  ==========*/
/*==========  GLOBAL  ==========*/
body {
  font-family: "Intro", sans-serif;
  color: #06040A; }

b, strong {
  font-weight: 700; }

a:hover {
  text-decoration: none; }

a {
  text-decoration: none;
  color: inherit; }

ul, ol {
  list-style: none; }

/*==========  SIZE  ==========*/
/* Text size */
body, .text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6em; }

.text-lg {
  font-size: 16px;
  line-height: 1.8em; }

.text-md {
  font-size: 14px;
  line-height: 1.7em; }

.text-sm {
  font-size: 12px;
  line-height: 1.6em; }

/* Headings */
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: #1C1642;
  font-weight: 300; }

h1, .h1 {
  font-size: 120px;
  line-height: 1.2em; }

.h1_2 {
  font-size: 120px;
  line-height: 1.2em; }

h2, .h2 {
  font-size: 40px;
  line-height: 1.2em; }

h3, .h3 {
  font-size: 30px;
  line-height: 1.2em; }

h4, .h4 {
  font-size: 24px;
  line-height: 1.2em; }

h5, .h5 {
  font-size: 20px;
  line-height: 1.4em; }

h6, .h6 {
  font-size: 14px;
  line-height: 1.4em; }

/*==========  MEDIA QUERIES  ==========*/
@media (max-width: 1600px) {
  h1, .h1 {
    font-size: 80px; }
  h2, .h2 {
    font-size: 28px; }
  h4, .h4 {
    font-size: 20px; }
  h5, .h5 {
    font-size: 18px; } }

@media (max-width: 1199px) {
  h1, .h1 {
    font-size: 40px; }
  h2, .h2 {
    font-size: 24px; }
  h4, .h4 {
    font-size: 20px; }
  .h4_xl {
    font-size: 14px; }
  .h4_xl2 {
    font-size: 16px; }
  h5, .h5 {
    font-size: 16px; }
  .h5_xl {
    font-size: 14px; }
  .h5_xl2 {
    font-size: 20px; }
  .text-md_xl {
    font-size: 12px; } }

@media (max-width: 991px) {
  body, .text {
    font-size: 14px;
    line-height: 1.4em; } }

/*==========  SIMPLE CONTENT  ==========*/
.text > * {
  margin-bottom: 26px; }
  .text > *:last-child {
    margin-bottom: 0; }

.text img {
  max-width: 100%;
  height: auto;
  display: block; }

.text ol {
  counter-reset: number; }
  .text ol li {
    padding-left: 0;
    margin-bottom: 8px;
    position: relative; }
    .text ol li:before {
      counter-increment: number;
      content: counter(number);
      margin-right: 8px;
      font-weight: 400;
      font-size: 14px;
      color: #111111; }
    .text ol li:last-of-type {
      margin-bottom: 0; }

.text ul li {
  padding-left: 30px;
  margin-bottom: 5px;
  position: relative; }
  .text ul li:before {
    content: "";
    display: inline-block;
    background-color: #111111;
    background-repeat: no-repeat;
    background-size: contain;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    vertical-align: middle;
    position: absolute;
    left: 12px;
    top: 0.7em; }
  .text ul li:last-of-type {
    margin-bottom: 0; }

.text > iframe {
  width: 100%;
  height: 480px;
  max-height: calc(56.3vw - 30px); }

.text b {
  font-weight: 400; }

.text strong {
  font-weight: 500; }

.text a {
  font-weight: 400;
  text-decoration: none;
  color: #724d9f;
  position: relative;
  padding-bottom: 4px; }
  .text a:after {
    content: '';
    display: block;
    height: 1px;
    width: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: all 0.34s ease-out;
    background-color: #724d9f; }

/* Table */
.text table,
.table {
  width: 100%;
  border-left: 1px solid #ECE7F2;
  border-top: 1px solid #ECE7F2;
  border-spacing: 0;
  line-height: 1.4em; }
  .text table th,
  .text table td,
  .table th,
  .table td {
    padding: 14px 16px;
    border-right: 1px solid #ECE7F2;
    border-bottom: 1px solid #ECE7F2; }
  .text table tr:nth-child(even) th,
  .text table tr:nth-child(even) td,
  .table tr:nth-child(even) th,
  .table tr:nth-child(even) td {
    background-color: #F9F8FB; }

.text blockquote,
.blockquote {
  padding: 40px 150px 20px 150px;
  position: relative;
  min-height: 150px; }
  .text blockquote:after,
  .blockquote:after {
    content: '';
    display: block;
    width: 96px;
    height: 96px;
    background-image: url("../../img/decor-00-03.svg");
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0; }
  .text blockquote:before,
  .blockquote:before {
    content: '';
    display: block;
    width: 96px;
    height: 96px;
    background-image: url("../../img/decor-00-04.svg");
    background-size: contain;
    position: absolute;
    bottom: 0;
    right: 0; }

/*==========  Hover  ==========*/
@media (min-width: 991px) {
  .text a:hover:after {
    width: 100%; } }

/*==========  MEDIA QUERIES  ==========*/
@media (max-width: 991px) {
  .text > * {
    margin-bottom: 15px; }
  .text ul li {
    padding-left: 25px; }
    .text ul li:before {
      left: 10px;
      top: 0.6em; }
  .table th,
  .table td {
    padding: 8px; }
  .blockquote {
    padding: 0; }
    .blockquote:after, .blockquote:before {
      display: none; } }

/* Font family*/
.font-fm-01 {
  font-family: "Intro", sans-serif; }

.font-fm-02 {
  font-family: "OpticianSans", sans-serif; }

/* Align */
.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

/* Text weight */
.fw-200 {
  font-weight: 200; }

.fw-300 {
  font-weight: 300; }

.fw-400 {
  font-weight: 400; }

.fw-500 {
  font-weight: 500; }

.fw-600 {
  font-weight: 600; }

.fw-700 {
  font-weight: 700; }

/* Text color */
.color-primary {
  color: #000000; }

.color-secondary {
  color: #B2A0BB; }

.color-white {
  color: #fff; }

.color-dark {
  color: #111; }

.color-grey-01 {
  color: #B0ACB1; }

.color-grey-02 {
  color: #767185; }

.color-dark-01 {
  color: #724d9f; }

.color-dark-02 {
  color: #B2A0BB; }

.color-dark-03 {
  color: #767185; }

.text-uppercase {
  text-transform: uppercase; }

.font1 {
  font-family: "Intro", sans-serif; }

.font2 {
  font-family: "OpticianSans", sans-serif; }

.ls-04 {
  letter-spacing: 0.04em; }

/*==========  Hover  ==========*/
/*==========  MEDIA QUERIES  ==========*/
@media (max-width: 1199px) {
  .text-xl-center {
    text-align: center; }
  .text-xl-left {
    text-align: left; }
  .text-xl-right {
    text-align: right; }
  .fw-xl-400 {
    font-weight: 400; } }

@media (max-width: 991px) {
  .text-lg-center {
    text-align: center; }
  .text-lg-right {
    text-align: right; }
  .text-lg-left {
    text-align: left; } }

/*==========  BUTTONS  ==========*/
.btn {
  display: inline-block;
  vertical-align: bottom;
  text-align: center;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  border: none;
  padding: 0;
  background: none;
  outline: none; }

.btn-primary {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7em;
  color: #fff;
  padding: 13px 40px 11px 40px;
  min-width: 175px;
  background-color: #06040A;
  position: relative;
  z-index: 0;
  border-radius: 2px;
  overflow: hidden;
  transition: all 0.34s ease-out;
  /*
	&:after{
		content: '';
		display: block;
		width: 0;
		height: 100%;
		background-color: #E6E6E7;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		transition: $transition;
	}
	*/ }
  .btn-primary span {
    position: relative;
    vertical-align: middle; }
  .btn-primary .icon {
    display: inline-block;
    line-height: 0;
    width: 24px;
    margin-right: 8px; }
    .btn-primary .icon img {
      width: 100%; }
  .btn-primary:active {
    background-color: rgba(6, 4, 10, 0.15); }
  .btn-primary.disabled {
    color: #B0ACB1;
    background-color: #ECEAEF;
    pointer-events: none; }
  .btn-primary.light {
    padding: 11px 40px;
    background-color: rgba(1, 1, 1, 0);
    border: 1px solid #FFFFFF; }
  .btn-primary.dark {
    padding: 11px 40px;
    color: #06040A;
    background-color: rgba(1, 1, 1, 0);
    border: 1px solid #06040A; }

.btn-secondary {
  border: 1px solid #06040A;
  font-size: 14px;
  line-height: 1.7em;
  font-weight: 500;
  color: #06040A;
  padding: 12px 20px;
  min-width: 175px;
  position: relative;
  z-index: 0;
  border-radius: 2px;
  overflow: hidden;
  transition: all 0.34s ease-out;
  /*
	&:after{
		content: '';
		display: block;
		width: 0;
		height: 100%;
		background-color: #F3F1F6;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		transition: $transition;
	}
	*/ }
  .btn-secondary span {
    position: relative;
    vertical-align: middle; }
  .btn-secondary .icon {
    display: inline-block;
    line-height: 0;
    width: 24px;
    margin-right: 8px; }
    .btn-secondary .icon img {
      width: 100%; }
  .btn-secondary:active {
    background-color: rgba(131, 115, 165, 0.15); }
  .btn-secondary.disabled {
    border: 1px solid #B0ACB1;
    color: #B0ACB1;
    pointer-events: none; }
  .btn-secondary.type2 {
    font-size: 12px;
    line-height: 1.4em;
    color: #06040A;
    background-color: #fff;
    border-color: #B2A0BB;
    padding: 10px 14px;
    min-width: 145px; }
    .btn-secondary.type2:after {
      background-color: #F9F8FB; }

.btn-icon {
  padding: 10px;
  min-width: 50px;
  border: 1px solid #B2A0BB;
  border-radius: 2px; }
  .btn-icon .icon {
    line-height: 0;
    width: 24px;
    display: block;
    margin: 0 auto; }
    .btn-icon .icon img {
      width: 100%; }
  .btn-icon .fill {
    fill: #B2A0BB;
    transition: all 0.34s ease-out; }
  .btn-icon:after {
    content: '';
    display: block;
    width: 0;
    height: 100%;
    background-color: #F9F8FB;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.34s ease-out; }
  .btn-icon:active {
    /*
		&:after{
			background-color: $color-dark-500;
		}
		.fill{
			fill: #FFFFFF;
		}
		*/ }

.btn-icon2 {
  padding: 9px;
  min-width: 48px;
  background-color: #F5F4FA;
  border-radius: 2px; }
  .btn-icon2 .icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: block;
    margin: 0 auto;
    text-align: center; }
    .btn-icon2 .icon svg {
      max-width: 100%;
      vertical-align: middle; }
    .btn-icon2 .icon img {
      width: 100%; }
    .btn-icon2 .icon .fill-bg {
      fill: rgba(1, 1, 1, 0);
      transition: all 0.34s ease-out; }
    .btn-icon2 .icon .fill-br {
      fill: #1C1642;
      transition: all 0.34s ease-out; }
  .btn-icon2.light {
    background-color: rgba(1, 1, 1, 0); }
  .btn-icon2.light2 {
    background-color: rgba(255, 255, 255, 0.4); }
  .btn-icon2.active .icon .fill-bg {
    fill: #724d9f; }
  .btn-icon2.active .icon .fill-br {
    fill: #724d9f; }
  .btn-icon2.disabled {
    background-color: #ECEAEF;
    pointer-events: none; }
    .btn-icon2.disabled .icon .fill-br {
      fill: #B0ACB1; }

.btn-icon3 {
  line-height: 0;
  width: 24px;
  transition: all 0.34s ease-out; }
  .btn-icon3 img {
    width: 100%; }
  .btn-icon3.md {
    width: 20px; }

.btn-link {
  line-height: 1.4em;
  font-weight: 400;
  color: #724d9f;
  text-align: left; }
  .btn-link:after {
    content: '';
    display: block;
    height: 1px;
    width: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: all 0.34s ease-out;
    background-color: #724d9f; }
  .btn-link:active {
    color: #1C1642;
    border-bottom-color: #1C1642; }
  .btn-link.xl {
    font-size: 20px; }
  .btn-link.md {
    font-size: 14px; }
  .btn-link.type2 {
    font-weight: 500;
    color: #1C1642; }

.btn-link2 {
  font-size: 14px;
  line-height: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  transition: all 0.34s ease-out; }
  .btn-link2:after {
    content: '';
    display: block;
    height: 1px;
    width: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: all 0.34s ease-out;
    background-color: #06040A; }
  .btn-link2 .icon {
    display: inline-block;
    width: 20px;
    line-height: 0;
    margin-right: 6px; }
    .btn-link2 .icon img {
      width: 100%; }
    .btn-link2 .icon svg {
      width: 100%; }
    .btn-link2 .icon .stroke {
      stroke: #06040A;
      transition: all 0.34s ease-out; }
    .btn-link2 .icon .fill {
      fill: #06040A;
      transition: all 0.34s ease-out; }
  .btn-link2.type2 {
    color: #8768AD; }
    .btn-link2.type2:after {
      background-color: #8768AD; }
    .btn-link2.type2 .icon .stroke {
      stroke: #8768AD; }
    .btn-link2.type2 .icon .fill {
      fill: #8768AD; }
  .btn-link2.type3 {
    font-weight: 500;
    color: #1C1642; }

.btn-link-icon {
  font-size: 14px;
  color: #06040A;
  padding-right: 38px;
  transition: all 0.34s ease-out; }
  .btn-link-icon .icon {
    display: block;
    width: 26px;
    line-height: 0;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%); }
    .btn-link-icon .icon img {
      width: 100%; }
  .btn-link-icon.left {
    padding-right: 0;
    padding-left: 38px; }
    .btn-link-icon.left .icon {
      right: auto;
      left: 0; }
  .btn-link-icon.disabled {
    pointer-events: none;
    color: #B0ACB1; }
  .btn-link-icon.type2 {
    font-weight: 300;
    color: #724d9f;
    padding-right: 35px; }
    .btn-link-icon.type2 .icon {
      width: 20px; }

.btn-block {
  display: block;
  width: 100%; }

.btn-close {
  width: 32px;
  height: 32px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #1C1642;
  position: relative;
  z-index: 1;
  cursor: pointer;
  outline: none;
  display: block;
  text-align: center;
  transition: all .37s ease-out; }
  .btn-close:after {
    content: "\02715"; }
  .btn-close.md {
    width: 24px;
    height: 24px;
    font-size: 22px;
    line-height: 24px; }

.btn-wrapper {
  display: flex;
  align-items: center; }
  .btn-wrapper.left {
    justify-content: flex-start; }
  .btn-wrapper.right {
    justify-content: flex-end; }
  .btn-wrapper.center {
    justify-content: center; }
  .btn-wrapper.full-2 .btn {
    flex: 0 0 calc(50% - 7.5px);
    padding-left: 5px;
    padding-right: 5px;
    min-width: 0; }
  .btn-wrapper > * {
    margin-right: 15px; }
    .btn-wrapper > *:last-child {
      margin-right: 0; }
  .btn-wrapper.sm > * {
    margin-right: 5px; }
    .btn-wrapper.sm > *:last-child {
      margin-right: 0; }
  .btn-wrapper.xl > * {
    margin-right: 40px; }
    .btn-wrapper.xl > *:last-child {
      margin-right: 0; }

@keyframes rotate {
  100% {
    transform: rotate(360deg); } }

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35; }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124; } }

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 50px;
  height: 50px; }
  .spinner.xl {
    width: 36px;
    height: 36px; }
  .spinner.md {
    width: 24px;
    height: 24px; }
  .spinner.sm {
    width: 16px;
    height: 16px; }
  .spinner .path {
    stroke: #fff;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite; }

.btn-loading .loading-icon {
  display: none; }

.btn-loading.loader {
  color: rgba(1, 1, 1, 0);
  pointer-events: none;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .btn-loading.loader .loading-icon {
    display: inline-block;
    line-height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.btn-loading.none {
  display: none !important; }

/*==========  Hover  ==========*/
@media (min-width: 992px) {
  .btn-primary:hover {
    background-color: rgba(6, 4, 10, 0.1);
    /*
		&:after{
			width: 100%;
			left: auto;
			right: 0;
		}
		*/ }
  .btn-secondary:not(.type2):hover {
    background-color: rgba(131, 115, 165, 0.1);
    /*
		&:after{
			width: 100%;
			left: auto;
			right: 0;
		}
		*/ }
  .btn-icon:hover:after {
    width: 100%;
    left: auto;
    right: 0; }
  .btn-link:hover:after {
    width: 100%; }
  .btn-link2:hover:after {
    width: 100%; }
  .btn-link-icon:hover {
    opacity: 0.8; }
  .btn-close:hover {
    color: #B2A0BB; }
  .btn-icon2:hover .icon .fill-bg {
    fill: #B2A0BB; }
  .btn-icon2:hover .icon .fill-br {
    fill: #B2A0BB; }
  .btn-icon3:hover {
    opacity: 0.8; } }

/*==========  MEDIA QUERIES  ==========*/
@media (max-width: 1600px) {
  .btn-primary {
    padding: 10px 20px 8px 20px;
    min-width: 150px; }
    .btn-primary.light {
      padding: 8px 20px; }
  .btn-secondary {
    padding: 8px 20px;
    min-width: 150px; }
  .btn-close {
    width: 24px;
    height: 24px;
    font-size: 18px;
    line-height: 24px; }
  .btn-icon2 {
    padding: 8px;
    min-width: 46px; } }

@media (max-width: 1199px) {
  .btn-primary {
    padding: 12px 22px 10px 22px;
    min-width: 175px; }
  .btn-secondary {
    padding: 11px 22px 9px 22px; } }

@media (max-width: 991px) {
  .btn-link-icon {
    font-size: 12px; } }

/*==========  Header Style  ==========*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99; }
  .header:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #E8E2EF;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    transition: all 0.34s ease-out; }
  .header-layer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: 0.2s; }
  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 10px 0; }
    .header-inner .logo {
      width: 180px;
      flex: 0 0 180px;
      line-height: 0;
      position: relative; }
      .header-inner .logo img {
        width: 100%;
        transition: all 0.34s ease-out; }
      .header-inner .logo .dark {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        opacity: 0; }
    .header-inner .nav .menu {
      font-size: 16px; }
      .header-inner .nav .menu > li {
        display: inline-block;
        margin-right: 45px; }
        .header-inner .nav .menu > li:last-child {
          margin-right: 0; }
        .header-inner .nav .menu > li > a {
          color: #fff;
          text-decoration: none; }
        .header-inner .nav .menu > li .icon {
          display: none; }
        .header-inner .nav .menu > li .sub-menu {
          position: absolute; }
        .header-inner .nav .menu > li .label-item {
          position: absolute;
          top: 50%;
          right: 30px;
          transform: translateY(-50%);
          display: none; }
    .header-inner .nav .sub-menu {
      position: absolute;
      background-color: #fff;
      top: 100%;
      width: 100%;
      left: 0;
      right: 0;
      padding-top: 20px;
      padding-bottom: 40px;
      opacity: 0;
      visibility: hidden;
      transform: translate(0, 10px);
      transition: opacity .2s ease-in,transform .2s ease-in,visibility .2s ease-in;
      transition-delay: 0.2s; }
      .header-inner .nav .sub-menu-inner {
        max-width: 1170px;
        margin-left: auto;
        margin-right: auto; }
      .header-inner .nav .sub-menu-item .title {
        font-size: 14px;
        margin-bottom: 4px; }
      .header-inner .nav .sub-menu-item li {
        margin-bottom: 4px; }
        .header-inner .nav .sub-menu-item li:last-child {
          margin-bottom: 0; }
      .header-inner .nav .sub-menu-item a {
        font-size: 14px;
        font-weight: 300;
        color: #1C1642;
        text-decoration: none; }
      .header-inner .nav .sub-menu-mobile {
        display: none; }
    .header-inner .nav-basic {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      .header-inner .nav-basic .item {
        display: block;
        flex: 0 0 32px;
        line-height: 0;
        margin-right: 30px; }
        .header-inner .nav-basic .item:last-child {
          margin-right: 0; }
        .header-inner .nav-basic .item .icon {
          display: block; }
        .header-inner .nav-basic .item svg {
          width: 100%; }
      .header-inner .nav-basic .stroke {
        transition: all 0.34s ease-out;
        stroke: #ffffff; }
      .header-inner .nav-basic .fill {
        transition: all 0.34s ease-out;
        fill: #ffffff; }
  .header-search {
    padding: 20px 0 40px 0;
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    transform: translateY(-30px);
    opacity: 0;
    visibility: hidden;
    transition: transform .5s,opacity .3s;
    max-height: calc(100vh - 100px);
    overflow-y: auto; }
    .header-search-input {
      position: relative; }
      .header-search-input .input {
        padding-left: 52px;
        padding-right: 52px; }
      .header-search-input .icon {
        display: inline-block;
        width: 32px;
        line-height: 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%); }
        .header-search-input .icon img {
          width: 100%; }
      .header-search-input .btn-close {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%); }
    .header-search-inner {
      max-width: 1320px;
      margin: 0 auto; }
    .header-search-results {
      margin-top: 28px; }
    .header-search-layer {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: none; }
  .header-notification {
    font-size: 14px;
    text-align: center;
    background-color: #B2A0BB;
    padding: 16px 70px 14px 70px;
    color: #fff;
    line-height: 1.2em;
    display: none; }
    .header-notification .btn-close {
      font-size: 18px;
      color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-weight: 400; }
  .header-burger {
    width: 22px;
    flex: 0 0 22px;
    height: 15px;
    position: relative;
    cursor: pointer;
    z-index: 4;
    margin-left: 15px;
    display: none; }
    .header-burger .close,
    .header-burger .open {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      .header-burger .close span,
      .header-burger .open span {
        width: 22px;
        height: 1px;
        display: block;
        position: absolute;
        background-color: #ffffff;
        border-radius: 30px;
        backface-visibility: hidden;
        transition: width 0.32s; }
    .header-burger .open span:nth-child(1) {
      top: 0;
      left: 0;
      transition-delay: 0.5s; }
    .header-burger .open span:nth-child(2) {
      top: 7px;
      right: 0;
      transition-delay: 0.55s; }
    .header-burger .open span:nth-child(3) {
      bottom: 0;
      left: 0;
      transition-delay: 0.6s; }
    .header-burger .close span {
      width: 0; }
      .header-burger .close span:nth-child(1) {
        top: 0px;
        left: 3px;
        transform: rotate(45deg);
        transform-origin: left;
        transition-delay: 0s; }
      .header-burger .close span:nth-child(2) {
        top: 0px;
        right: 4px;
        transform: rotate(-45deg);
        transform-origin: right;
        transition-delay: 0.08s; }
  .header-social {
    display: flex; }
    .header-social a {
      display: block;
      width: 24px;
      line-height: 0;
      margin-right: 20px; }
      .header-social a svg {
        width: 100%; }
      .header-social a .fill {
        fill: #767185; }
      .header-social a .stroke {
        stroke: #767185; }
    .header-social-wrapper {
      padding: 15px 30px 0 30px; }
  .header.active-menu:after {
    border-bottom-color: rgba(1, 1, 1, 0); }
  .header.active-menu .header-burger .close span {
    width: 22px; }
    .header.active-menu .header-burger .close span:nth-child(1) {
      transition-delay: 0.5s; }
    .header.active-menu .header-burger .close span:nth-child(2) {
      transition-delay: 0.58s; }
  .header.active-menu .header-burger .open span {
    width: 0; }
    .header.active-menu .header-burger .open span:nth-child(1) {
      transition-delay: 0s; }
    .header.active-menu .header-burger .open span:nth-child(2) {
      transition-delay: 0.05s; }
    .header.active-menu .header-burger .open span:nth-child(3) {
      transition-delay: 0.1s; }
  .header.active-notification .header-notification {
    display: block; }
  .header.active-search .header-layer {
    visibility: visible;
    opacity: 1; }
  .header.active-search .header-search {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); }
  .header.active-search .header-search-layer {
    display: block; }
  .header.active-basket .header-layer {
    visibility: visible;
    opacity: 1;
    z-index: 9; }
  .header.active-basket .header-basket {
    transform: none; }
  .header.active-basket .header-basket-layer {
    display: block; }
  .header.active-menu:after, .header.active-basket:after, .header.active-search:after, .header.scrolled:after {
    visibility: visible;
    opacity: 1; }
  .header.active-menu .header-inner .logo .dark, .header.active-basket .header-inner .logo .dark, .header.active-search .header-inner .logo .dark, .header.scrolled .header-inner .logo .dark {
    visibility: visible;
    opacity: 1; }
  .header.active-menu .header-inner .logo .light, .header.active-basket .header-inner .logo .light, .header.active-search .header-inner .logo .light, .header.scrolled .header-inner .logo .light {
    visibility: hidden;
    opacity: 0; }
  .header.active-menu .header-inner .nav-basic .stroke, .header.active-basket .header-inner .nav-basic .stroke, .header.active-search .header-inner .nav-basic .stroke, .header.scrolled .header-inner .nav-basic .stroke {
    stroke: #1C1642; }
  .header.active-menu .header-inner .nav-basic .fill, .header.active-basket .header-inner .nav-basic .fill, .header.active-search .header-inner .nav-basic .fill, .header.scrolled .header-inner .nav-basic .fill {
    fill: #1C1642; }
  .header.active-menu .header-inner .nav .menu > li > a, .header.active-basket .header-inner .nav .menu > li > a, .header.active-search .header-inner .nav .menu > li > a, .header.scrolled .header-inner .nav .menu > li > a {
    color: #1C1642; }
  .header.active-menu .header-inner .header-burger .open span,
  .header.active-menu .header-inner .header-burger .close span, .header.active-basket .header-inner .header-burger .open span,
  .header.active-basket .header-inner .header-burger .close span, .header.active-search .header-inner .header-burger .open span,
  .header.active-search .header-inner .header-burger .close span, .header.scrolled .header-inner .header-burger .open span,
  .header.scrolled .header-inner .header-burger .close span {
    background-color: #1C1642; }

.header-basket {
  background-color: #fff;
  width: 100%;
  max-width: 460px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateX(100%);
  transition: all .4s ease-out;
  z-index: 99; }
  .header-basket-inner {
    height: 100vh;
    padding: 20px 20px 150px 20px;
    overflow: auto; }
  .header-basket-total {
    padding: 20px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff; }
    .header-basket-total .subtotal {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding-top: 20px;
      border-top: 1px solid #E8E2EF; }
      .header-basket-total .subtotal .title {
        margin-right: 15px; }
  .header-basket-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 98;
    display: none; }
  .header-basket-title {
    font-size: 20px;
    position: relative;
    padding-left: 100px;
    padding-right: 100px;
    text-align: center; }
    .header-basket-title .btn-close {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%); }
  .header-basket-quantity {
    font-size: 12px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%); }
    .header-basket-quantity-mob {
      display: none; }
  .header-basket-empty {
    padding-top: 100px;
    text-align: center; }
    .header-basket-empty .icon {
      display: block;
      width: 158px;
      line-height: 0;
      margin: 0 auto 40px auto; }
      .header-basket-empty .icon img {
        width: 100%; }
    .header-basket-empty .text-in {
      margin-bottom: 60px;
      max-width: 250px;
      display: inline-block; }

.not-main-page .header:after {
  visibility: visible;
  opacity: 1; }

.not-main-page .header .header-inner .logo .dark {
  visibility: visible;
  opacity: 1; }

.not-main-page .header .header-inner .logo .light {
  visibility: hidden;
  opacity: 0; }

.not-main-page .header .header-inner .nav-basic .stroke {
  stroke: #1C1642; }

.not-main-page .header .header-inner .nav-basic .fill {
  fill: #1C1642; }

.not-main-page .header .header-inner .nav .menu > li > a {
  color: #1C1642; }

.not-main-page .header .header-inner .header-burger .open span,
.not-main-page .header .header-inner .header-burger .close span {
  background-color: #1C1642; }

.dropdown-lang {
  background: #fff;
  box-shadow: 8px 8px 29px rgba(104, 104, 173, 0.09);
  border-radius: 65px;
  position: fixed;
  left: 40px;
  bottom: 140px;
  z-index: 55;
  display: flex; }
  .dropdown-lang:after {
    content: '';
    width: 1px;
    height: 30px;
    background-color: #E8E2EF;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .dropdown-lang-content {
    cursor: pointer;
    position: relative; }
    .dropdown-lang-content.lf {
      padding: 23px 14px 23px 20px; }
    .dropdown-lang-content.rg {
      padding: 23px 20px 23px 14px; }
    .dropdown-lang-content .inner {
      padding-right: 28px;
      position: relative;
      height: 22px;
      display: flex;
      align-items: center; }
      .dropdown-lang-content .inner:after {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background-image: url("../img/icons/icon-arrow-bt-0291.svg");
        background-size: 100%;
        background-position: center;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        transition: all 0.34s ease-out; }
      .dropdown-lang-content .inner .icon {
        display: inline-block;
        width: 34px;
        line-height: 0;
        position: relative; }
        .dropdown-lang-content .inner .icon img {
          width: 100%; }
    .dropdown-lang-content .list {
      background-color: #fff;
      position: absolute;
      min-width: 100%;
      padding: 10px 0;
      left: 0;
      top: calc(100% + 4px);
      border-radius: 6px;
      cursor: default;
      box-shadow: 8px 8px 29px rgba(104, 104, 173, 0.09);
      visibility: hidden;
      transform: translateY(15px);
      opacity: 0;
      transition: all 0.34s ease-out;
      transition-delay: 0.2s; }
      .dropdown-lang-content .list li {
        margin-bottom: 2px; }
        .dropdown-lang-content .list li:last-child {
          margin-bottom: 0; }
        .dropdown-lang-content .list li a {
          display: block;
          padding: 4px 14px;
          text-decoration: none;
          color: inherit;
          cursor: pointer;
          line-height: 1.2em; }
        .dropdown-lang-content .list li .icon {
          display: inline-block;
          line-height: 0;
          width: 32px;
          vertical-align: middle; }
          .dropdown-lang-content .list li .icon img {
            width: 100%; }
        .dropdown-lang-content .list li.active {
          pointer-events: none;
          background-color: #E8E2EF; }
          .dropdown-lang-content .list li.active a {
            pointer-events: none; }

/*==========  Hover  ==========*/
@media (min-width: 991px) {
  .header:hover:after {
    visibility: visible;
    opacity: 1; }
  .header:hover .header-inner .logo .dark {
    visibility: visible;
    opacity: 1; }
  .header:hover .header-inner .logo .light {
    visibility: hidden;
    opacity: 0; }
  .header:hover .header-inner .nav-basic .stroke {
    stroke: #1C1642; }
  .header:hover .header-inner .nav-basic .fill {
    fill: #1C1642; }
  .header:hover .header-inner .nav .menu > li > a {
    color: #1C1642; }
  .header-inner .nav .menu > li > a:hover {
    opacity: 0.8; }
  .header-inner .nav .sub-menu-item a:hover {
    opacity: 0.8; }
  .header-inner .nav .menu > li:hover .sub-menu {
    transition-delay: 0s;
    opacity: 1;
    visibility: visible;
    transform: none; }
  .header-inner .nav-basic .item:hover .fill {
    fill: #B2A0BB; }
  .header-inner .nav-basic .item:hover .stroke {
    stroke: #B2A0BB; }
  .dropdown-lang-content .list li a:hover {
    background-color: #E8E2EF; }
  .dropdown-lang-content:hover .inner:after {
    transform: translateY(-50%) rotate(-180deg); }
  .dropdown-lang-content:hover .list {
    transition-delay: 0s;
    visibility: visible;
    opacity: 1;
    transform: none; } }

/*==========  MEDIA QUERIES  ==========*/
@media (max-width: 1600px) {
  .header-inner {
    height: 80px; }
    .header-inner .logo {
      width: 160px;
      flex: 0 0 160px; }
    .header-inner .nav .menu > li {
      margin-right: 20px; }
    .header-inner .nav .sub-menu-item li {
      line-height: 1.5em; }
    .header-inner .nav-basic .item {
      margin-right: 20px; }
  .header-search-input .input {
    padding-left: 36px;
    padding-right: 36px; }
  .header-search-input .icon {
    width: 24px; }
  .header-notification {
    padding: 10px 50px 8px 50px; }
  .dropdown-lang {
    left: 50px;
    bottom: 120px; }
    .dropdown-lang-content.lf {
      padding: 16px 14px 16px 16px; }
    .dropdown-lang-content.rg {
      padding: 16px 16px 16px 14px; }
    .dropdown-lang-content .inner {
      padding-right: 24px; }
      .dropdown-lang-content .inner .icon {
        width: 28px; }
      .dropdown-lang-content .inner:after {
        width: 18px;
        height: 18px; }
    .dropdown-lang-content .list li .icon {
      line-height: 0;
      width: 28px; } }

@media (max-width: 1368px) {
  .header-inner .nav .menu > li {
    margin-right: 15px; } }

@media (max-width: 1199px) {
  .header-inner {
    height: 60px; }
    .header-inner .nav {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      padding: 78px 0 24px 0;
      background-color: #fff;
      height: 100%;
      z-index: -1;
      overflow-y: auto;
      transform: translateX(100%);
      transition: 0.48s; }
      .header-inner .nav .menu > li {
        display: block;
        margin-right: 0;
        padding-right: 60px;
        position: relative; }
        .header-inner .nav .menu > li .icon {
          display: block;
          width: 26px;
          line-height: 0;
          position: absolute;
          top: 50%;
          right: 30px;
          transform: translateY(-50%);
          transition: all 0.34s ease-out; }
          .header-inner .nav .menu > li .icon img {
            width: 100%; }
        .header-inner .nav .menu > li > a {
          font-size: 16px;
          display: block;
          padding: 12px 30px; }
        .header-inner .nav .menu > li.active .sub-menu-icon {
          transform: rotate(180deg) !important; }
        .header-inner .nav .menu > li .label-item {
          display: inline-block; }
      .header-inner .nav .menu.type2 {
        border-top: 1px solid #E8E2EF; }
        .header-inner .nav .menu.type2 > li {
          border-bottom: 1px solid #E8E2EF; }
      .header-inner .nav .sub-menu {
        display: none; }
        .header-inner .nav .sub-menu-icon {
          top: 8px !important;
          transform: translateY(0) !important; }
        .header-inner .nav .sub-menu-mobile {
          display: block;
          padding: 0 30px 0 30px;
          display: none; }
          .header-inner .nav .sub-menu-mobile li {
            margin-bottom: 8px;
            padding: 0 15px; }
            .header-inner .nav .sub-menu-mobile li:last-child {
              margin-bottom: 0; }
            .header-inner .nav .sub-menu-mobile li > a {
              font-size: 14px;
              color: #1C1642;
              font-weight: 300;
              text-decoration: none; }
      .header-inner .nav-setting-mobile {
        padding: 16px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
      .header-inner .nav-languages {
        display: flex; }
        .header-inner .nav-languages a {
          width: 44px;
          height: 44px;
          overflow: hidden;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 32px;
          filter: grayscale(1);
          opacity: 0.3; }
          .header-inner .nav-languages a.active {
            filter: grayscale(0);
            opacity: 1; }
          .header-inner .nav-languages a img {
            width: 170%;
            height: 170%; }
    .header-inner .nav-basic {
      margin-left: auto; }
      .header-inner .nav-basic .item {
        flex: 0 0 24px;
        margin-right: 15px; }
    .header-inner .logo {
      width: 142px;
      flex: 0 0 142px; }
  .header-burger {
    display: block; }
  .header-notification {
    font-size: 12px;
    padding: 12px 50px 12px 50px; }
  .header.active-notification .header-inner .nav {
    padding-top: 110px; }
  .header.active-menu .header-inner .nav {
    transform: none; }
  .header-basket-inner {
    padding: 20px 15px 150px 15px; }
  .header-basket-quantity {
    display: none; }
    .header-basket-quantity-mob {
      color: #767185;
      display: inline-block; }
  .header-basket-title {
    font-size: 20px;
    padding-right: 30px;
    padding-left: 0;
    text-align: left; }
    .header-basket-title .btn-close {
      left: auto;
      right: 0; }
  .header-basket-total {
    padding: 20px 15px; }
    .header-basket-total .subtotal {
      padding-top: 12px;
      margin-bottom: 12px; }
  .dropdown-lang {
    display: none; } }

/*==========  Banner Style  ==========*/
.banner {
  position: relative; }
  .banner-inner {
    text-align: center;
    display: table-cell;
    height: 100vh;
    width: 999999px;
    vertical-align: middle;
    padding: 200px 0; }
    .banner-inner .bg {
      background-position: center; }
  .banner-content {
    position: relative;
    z-index: 2; }
  .banner-nav {
    padding-bottom: 62px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 50%; }
    .banner-nav-inner {
      display: flex;
      align-items: flex-end; }
  .banner-product-wrapper {
    padding-bottom: 62px;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 50%; }
  .banner-product {
    width: 240px;
    display: flex;
    align-items: center;
    margin-left: auto; }
    .banner-product .content {
      font-size: 14px;
      padding-right: 20px;
      text-align: right;
      color: #fff;
      width: 100%; }
    .banner-product .name {
      display: inline-block;
      color: #fff;
      text-decoration: none;
      margin-bottom: 6px;
      cursor: pointer; }
    .banner-product .price {
      margin-top: 4px; }
    .banner-product .line {
      height: 1px;
      background-color: #fff;
      position: relative; }
      .banner-product .line:after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: #fff; }
    .banner-product .image {
      position: relative;
      width: 60px;
      height: 60px;
      flex: 0 0 60px;
      border-radius: 50%;
      overflow: hidden; }
  .banner-product-name {
    font-size: 12px;
    color: #ffffff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
    padding: 0 15px;
    text-align: center;
    display: none; }

/*==========  Hover  ==========*/
@media (min-width: 991px) {
  .banner-product .name:hover {
    text-decoration: underline; } }

/*==========  MEDIA QUERIES  ==========*/
@media (max-width: 1199px) {
  .banner-inner {
    padding: 150px 0; }
  .banner-nav {
    padding-bottom: 20px;
    max-width: 100%; }
    .banner-nav-inner {
      justify-content: center; }
  .banner-product-wrapper {
    display: none; }
  .banner-product-name {
    display: block; } }

/*==========  Swiper Style  ==========*/
.swiper-entry {
  position: relative; }

.swiper-container {
  z-index: auto; }

/*
.swiper-container:not(.swiper-no-swiping) .swiper-wrapper {
  cursor: url(../img/drag.png) 16 9, ew-resize;
}
.swiper-container-vertical:not(.swiper-no-swiping) .swiper-wrapper {
  cursor: url(../img/drag-vertical.png) 8 16, ns-resize;
}
*/
.swiper-entry.swiper-style1 .swiper-pagination {
  position: relative;
  line-height: 0; }

.swiper-entry.swiper-style1 .swiper-pagination-bullet {
  background-color: #808284;
  opacity: 1;
  margin-right: 10px;
  transition: all 0.34s ease-out; }
  .swiper-entry.swiper-style1 .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #ffffff; }

.swiper-entry.swiper-style2 .swiper-pagination {
  line-height: 0;
  bottom: 10px;
  width: 100%;
  padding: 10px 15px; }

.swiper-entry.swiper-style2 .swiper-pagination-bullet {
  background: rgba(255, 255, 255, 0.4);
  opacity: 1;
  margin-right: 10px;
  transition: all 0.34s ease-out; }
  .swiper-entry.swiper-style2 .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #ffffff; }

.swiper-entry.inst-detail-popup {
  height: 100%;
  max-height: calc(100vh - 30px);
  position: sticky;
  top: 0; }
  .swiper-entry.inst-detail-popup .swiper-container,
  .swiper-entry.inst-detail-popup .swiper-wrapper,
  .swiper-entry.inst-detail-popup .swiper-slide {
    height: 100%; }

.swiper-entry.capsule-detail-popup {
  height: 100%; }
  .swiper-entry.capsule-detail-popup .swiper-container,
  .swiper-entry.capsule-detail-popup .swiper-wrapper,
  .swiper-entry.capsule-detail-popup .swiper-slide {
    height: 100%; }

.swiper-entry.swiper-style3 .swiper-button-prev,
.swiper-entry.swiper-style3 .swiper-button-next {
  width: 36px;
  height: 36px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.4);
  opacity: 1 !important;
  transition: all 0.34s ease-out; }

.swiper-entry.swiper-style3 .swiper-button-next {
  background-image: url("../img/icons/icon-arrow-right.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center; }

.swiper-entry.swiper-style3 .swiper-button-prev {
  background-image: url("../img/icons/icon-arrow-left.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center; }

.swiper-entry.swiper-style3 .swiper-pagination {
  line-height: 0;
  bottom: 10px;
  width: 100%;
  padding: 10px 15px; }

.swiper-entry.swiper-style3 .swiper-pagination-bullet {
  background-color: rgba(255, 255, 255, 0.4);
  opacity: 1;
  margin-right: 10px;
  transition: all 0.34s ease-out; }
  .swiper-entry.swiper-style3 .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #ffffff; }

.swiper-entry.swiper-style4 .swiper-slide img {
  width: 100%; }

.swiper-entry.swiper-style4 .swiper-container-3d .swiper-slide-shadow-left,
.swiper-entry.swiper-style4 .swiper-container-3d .swiper-slide-shadow-right {
  display: none; }

.swiper-entry.swiper-style5 {
  width: calc(100% + 15px); }
  .swiper-entry.swiper-style5 .swiper-container {
    padding-right: 20px; }

@media (min-width: 991px) {
  .swiper-entry.swiper-style4 .swiper-slide {
    text-align: center;
    width: 25% !important;
    border: 1px solid rgba(1, 1, 1, 0.06);
    line-height: 0; }
  .swiper-entry.swiper-style4 .swiper-slide img {
    width: 100%; } }

/*==========  Hover  ==========*/
@media (min-width: 991px) {
  .swiper-entry.swiper-style3 .swiper-button-prev:hover,
  .swiper-entry.swiper-style3 .swiper-button-next:hover {
    background-color: rgba(255, 255, 255, 0.6); } }

/*==========  MEDIA QUERIES  ==========*/
@media (max-width: 1199px) {
  .swiper-entry.swiper-style1 .swiper-pagination-bullet {
    background-color: rgba(255, 255, 255, 0.4);
    margin-right: 5px;
    margin-left: 5px; }
  .swiper-entry.swiper-style3 .swiper-button-prev,
  .swiper-entry.swiper-style3 .swiper-button-next {
    width: 24px;
    height: 24px;
    background-size: 16px; } }

/*==========  Form Style  ==========*/
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #222;
  opacity: 1; }

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #222;
  opacity: 1; }

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #222;
  opacity: 1; }

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #222;
  opacity: 1; }

input, textarea, select {
  border-radius: 0;
  background: none;
  border: none;
  margin: 0;
  width: 100%;
  padding: 0; }

textarea {
  display: block;
  resize: none;
  overflow: auto; }

select::-ms-expand {
  display: none; }

.invalid {
  border-color: red;
  color: red; }

/*==========  Text fields  ==========*/
.input-error {
  margin-top: 5px;
  color: #FA665B;
  font-size: 14px; }

.input {
  font-size: 16px;
  color: #06040A;
  height: 44px;
  padding: 0;
  border-bottom: 1px solid #06040A;
  outline: none;
  transition: 0.24s; }
  .input::placeholder {
    font-size: 16px;
    font-weight: 300;
    color: #767185;
    transition: 0.24s; }
  .input:focus::placeholder {
    color: #B0ACB1 !important; }
  .input:disabled, .input.dispbled {
    pointer-events: none;
    color: #AAAAAA; }
  .input.error {
    box-shadow: 0px 0px 0px 1.5px #E26371; }
    .input.error + .input-error {
      display: block; }
  .input-inner {
    position: relative; }
    .input-inner .toggle {
      display: block;
      position: absolute;
      line-height: 0;
      top: 50%;
      right: 0;
      transform: translateY(-50%); }
      .input-inner .toggle .icon {
        display: block;
        width: 24px;
        line-height: 0; }
        .input-inner .toggle .icon.hide {
          display: none; }
    .input-inner.hide .icon.show {
      display: none; }
    .input-inner.hide .icon.hide {
      display: block; }
    .input-inner.input-hidden-text .input {
      padding-right: 30px; }
    .input-inner:before {
      content: "";
      position: absolute;
      top: auto;
      bottom: 1px;
      left: 0;
      right: 100%;
      transition: right .5s linear;
      border-bottom: 1px solid #000;
      z-index: 2; }
    .input-inner:not(.no_underline):focus-within:before {
      right: 0; }

textarea.input {
  padding-top: 15px;
  line-height: 18px;
  min-height: 44px;
  resize: vertical; }

/*==========  Text fields (phone) ==========*/
.input-phone .iti--allow-dropdown {
  width: 100%; }

.input-phone .iti--allow-dropdown .iti__flag-container {
  position: static; }

.input-phone .iti--allow-dropdown .iti__selected-flag {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(1, 1, 1, 0);
  padding: 0 4px 0 0;
  font-size: 16px;
  font-weight: 300;
  color: #767185; }
  .input-phone .iti--allow-dropdown .iti__selected-flag:after {
    content: '';
    display: block;
    height: calc(100% - 14px);
    width: 1px;
    background-color: #06040A;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }

.input-phone .iti--allow-dropdown .iti__country-list {
  top: 100%;
  width: 100%;
  box-shadow: none;
  border: 1px solid rgba(1, 1, 1, 0.08);
  overflow-x: hidden; }

.input-phone .iti__selected-dial-code {
  margin-left: 0; }

.input-phone .iti__arrow {
  width: 24px;
  height: 24px;
  border: 0;
  margin-left: 4px;
  background-image: url("../img/icons/icon-arrow-03932.svg");
  background-position: center;
  background-size: 100%; }

.input-phone .iti__selected-flag .iti__flag {
  display: none; }

/*==========  Text fields (datepicker) ==========*/
.input-datepicker .datepicker-cell.selected {
  font-weight: 400;
  background-color: #B2A0BB; }

.input-datepicker .datepicker-picker span {
  border-radius: 0; }

.input-datepicker .datepicker-dropdown .datepicker-picker {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid rgba(1, 1, 1, 0.08); }

/*==========  Hover  ==========*/
@media (min-width: 991px) {
  .input:hover::placeholder {
    color: #06040A; } }

/*==========  MEDIA QUERIES  ==========*/
@media (max-width: 991px) {
  .input {
    font-size: 14px; } }

/*==========  Checkbox Style  ==========*/
.checkbox-entry {
  display: inline-block;
  cursor: pointer;
  margin: 0;
  position: relative;
  padding-left: 34px;
  line-height: 1.4em;
  transition: all .34s ease-out; }
  .checkbox-entry.checkbox-block {
    display: block;
    width: 100%; }
  .checkbox-entry input {
    display: none; }
  .checkbox-entry span {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: 1px solid #B2A0BB;
    border-radius: 1px;
    background-color: #fff;
    transition: all 0.34s ease-out; }
  .checkbox-entry span:after {
    content: "";
    position: absolute;
    left: 6px;
    top: 42%;
    transform: translateY(-50%) rotate(45deg);
    width: 7px;
    height: 11px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    border-right: 2px solid #E8E2EF;
    border-bottom: 2px solid #E8E2EF;
    transition: all 0.34s ease-out; }
  .checkbox-entry input:checked + span {
    border-color: #8768AD; }
  .checkbox-entry input:checked + span:after {
    opacity: 1;
    visibility: visible;
    border-color: #8768AD !important; }
  .checkbox-entry.radio {
    padding-left: 28px; }
  .checkbox-entry input[type=radio] + span {
    width: 18px;
    height: 18px; }
  .checkbox-entry input[type=radio] + span:after {
    width: 10px;
    height: 10px;
    border: none;
    top: 50%;
    left: 3px;
    background-color: #E8E2EF; }
  .checkbox-entry input[type=radio] + span,
  .checkbox-entry input[type=radio] + span:after {
    border-radius: 50%; }
  .checkbox-entry input[type=radio]:checked + span:after {
    opacity: 1;
    visibility: visible;
    background-color: #8768AD !important; }
  .checkbox-entry.disable {
    pointer-events: none; }
    .checkbox-entry.disable span:before {
      background-color: #F0F0F0; }
  .checkbox-entry.disable-checked {
    pointer-events: none; }
    .checkbox-entry.disable-checked input:checked + span:after {
      border-color: #AAAAAA; }
    .checkbox-entry.disable-checked input:checked + span:before {
      border-color: #F0F0F0;
      background-color: rgba(1, 1, 1, 0); }
    .checkbox-entry.disable-checked input[type=radio] + span:after {
      background-color: #F0F0F0; }

.checkbox-entry2 {
  display: block;
  position: relative;
  padding: 4px 20px;
  cursor: pointer;
  transition: all 0.34s ease-out; }
  .checkbox-entry2 input {
    display: none; }
  .checkbox-entry2 span.layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }
  .checkbox-entry2 .icon {
    display: inline-block;
    width: 12px;
    line-height: 0;
    margin-left: 8px; }
    .checkbox-entry2 .icon img {
      width: 100%; }
  .checkbox-entry2 span {
    vertical-align: middle; }
  .checkbox-entry2 input:checked + span.layer {
    background-color: #F5F4FA; }

.checkbox-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -15px; }
  .checkbox-wrapper .label {
    margin-bottom: 15px;
    margin-right: 15px; }
  .checkbox-wrapper .checkbox-entry {
    display: block;
    margin-bottom: 15px;
    margin-right: 15px; }
  .checkbox-wrapper.center {
    justify-content: center; }

.checkbox-entry-wrapper .error-text {
  display: none;
  margin-top: 4px;
  color: #FA665B;
  font-size: 14px;
  line-height: 0.9em; }

.checkbox-entry-wrapper.error .error-text {
  display: block; }

/*==========  HOVER  ==========*/
@media (min-width: 992px) {
  .checkbox-entry:hover span {
    border-color: #8768AD; }
    .checkbox-entry:hover span:after {
      opacity: 1;
      visibility: visible;
      border-color: #E8E2EF; }
  .checkbox-entry2:hover {
    background-color: #F5F4FA; } }

/*==========  MEDIA QUERIES  ==========*/
@media (max-width: 991px) {
  .checkbox-wrapper .label {
    flex: 0 0 100%;
    margin-right: 0; } }

/*==========  Decor Style  ==========*/
.decor {
  position: absolute;
  z-index: -1;
  line-height: 0; }
  .decor img {
    max-width: 100%; }
  .decor.ps-01 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 220px; }

/*==========  MEDIA QUERIES  ==========*/
@media (max-width: 1600px) {
  .decor.ps-01 {
    width: 150px; } }

@media (max-width: 1199px) {
  .decor.ps-01 {
    width: 110px; } }
