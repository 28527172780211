/*-------------------------------------------------------------------------------------------------------------------------------*/
/* GLOBAL SETTINGS */
/*-------------------------------------------------------------------------------------------------------------------------------*/

html {
	height: 100%;
}

body {
	height: 100%;
	//overflow: hidden;
}

#content-block {
	//overflow: hidden;
	position: relative;
	min-height: 100vh;
	background: #FCFCFE;
	&.not-main-page{
		padding-top: 100px;
	}
}

.overflow-hidden {
	overflow: hidden;
}

html.overflow-hidden body {
	overflow: hidden;
}

/*==========  MEDIA QUERIES  ==========*/

@media (max-width: 1600px){
	#content-block{
		&.not-main-page {
			padding-top: 80px;
		}
	}
}

@media (max-width: 1199px){
	#content-block {
		overflow: hidden;
		&.not-main-page {
			padding-top: 60px;
		}
	}
}
