/*==========  Decor Style  ==========*/
.decor{
  position: absolute;
  z-index: -1;
  line-height: 0;
  img{
    max-width: 100%;
  }
  &.ps-01{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 220px;
  }
}

/*==========  MEDIA QUERIES  ==========*/

@media (max-width: 1600px){
  .decor {
    &.ps-01 {
      width: 150px;
    }
  }
}

@media (max-width: 1199px){
  .decor {
    &.ps-01 {
      width: 110px;
    }
  }
}

//@media (max-width: 991px){}

//@media (max-width: 768px){}

// @media (max-width: 575px){}



