/*==========  Header Style  ==========*/
.header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  &:after{
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #ffffff;
    border-bottom: 1px solid $color-lilac-300;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    transition: $transition;
  }
  &-layer{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: 0.2s;
  }
  &-inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 10px 0;
    .logo{
        width: 180px;
        flex: 0 0 180px;
        line-height: 0;
        position: relative;
        img{
          width: 100%;
          transition: $transition;
        }
        .dark{
          position: absolute;
          top: 0;
          left: 0;
          visibility: hidden;
          opacity: 0;
        }
    }
    .nav{
      .menu{
        font-size: 16px;
        > li{
          display: inline-block;
          margin-right: 45px;
          &:last-child{
            margin-right: 0;
          }
          > a{
            color: #fff;
            text-decoration: none;
          }
          .icon{
            display: none;
          }
          .sub-menu{
            position: absolute;
          }
          .label-item{
            position: absolute;
            top: 50%;
            right: 30px;
            transform: translateY(-50%);
            display: none;
          }
        }
      }
      .sub-menu{
        position: absolute;
        background-color: #fff;
        top: 100%;
        width: 100%;
        left: 0;
        right: 0;
        padding-top: 20px;
        padding-bottom: 40px;
        opacity: 0;
        visibility: hidden;
        transform: translate(0,10px);
        transition: opacity .2s ease-in,transform .2s ease-in,visibility .2s ease-in;
        transition-delay: 0.2s;
        &-inner{
          max-width: 1170px;
          margin-left: auto;
          margin-right: auto;
        }
        &-item{
          .title{
            font-size: 14px;
            margin-bottom: 4px;
          }
          li{
            margin-bottom: 4px;
            &:last-child{
              margin-bottom: 0;
            }
          }
          a{
            font-size: 14px;
            font-weight: 300;
            color: $color-dark-800;
            text-decoration: none;
          }
        }
        &-mobile{
          display: none;
        }
      }
    }
    .nav-basic{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .item{
        display: block;
        flex: 0 0 32px;
        line-height: 0;
        margin-right: 30px;
        &:last-child{
          margin-right: 0;
        }
        .icon{
          display: block;
        }
        svg{
          width: 100%;
        }
      }
      .stroke{
        transition: $transition;
        stroke: #ffffff;
      }
      .fill{
        transition: $transition;
        fill: #ffffff;
      }
    }
  }
  &-search{
    padding: 20px 0 40px 0;
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    transform: translateY(-30px);
    opacity: 0;
    visibility: hidden;
    transition: transform .5s,opacity .3s;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    &-input{
      position: relative;
      .input{
        padding-left: 52px;
        padding-right: 52px;
      }
      .icon{
        display: inline-block;
        width: 32px;
        line-height: 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        img{
          width: 100%;
        }
      }
      .btn-close{
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
    &-inner{
      max-width: 1320px;
      margin: 0 auto;
    }
    &-results{
      margin-top: 28px;
    }
    &-layer{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: none;
    }
  }
  &-notification{
    font-size: 14px;
    text-align: center;
    background-color: #B2A0BB;
    padding: 16px 70px 14px 70px;
    color: #fff;
    line-height: 1.2em;
    display: none;
    .btn-close{
      font-size: 18px;
      color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-weight: 400;
    }
  }
  &-burger{
    width: 22px;
    flex: 0 0 22px;
    height: 15px;
    position: relative;
    cursor: pointer;
    z-index: 4;
    margin-left: 15px;
    display: none;
    .close,
    .open{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      span{
        width: 22px;
        height: 1px;
        display: block;
        position: absolute;
        background-color: #ffffff;
        border-radius: 30px;
        backface-visibility: hidden;
        transition: width 0.32s;
      }
    }
    .open{
      span{
        &:nth-child(1){
          top: 0;
          left: 0;
          transition-delay: 0.5s;
        }
        &:nth-child(2){
          top: 7px;
          right: 0;
          transition-delay: 0.55s;
        }
        &:nth-child(3){
          bottom: 0;
          left: 0;
          transition-delay: 0.6s;
        }
      }
    }
    .close{
      span{
        width: 0;
        &:nth-child(1){
          top: 0px;
          left: 3px;
          transform: rotate(45deg);
          transform-origin: left;
          transition-delay: 0s;
        }
        &:nth-child(2){
          top: 0px;
          right: 4px;
          transform: rotate(-45deg);
          transform-origin: right;
          transition-delay: 0.08s;
        }
      }
    }
  }
  &-social{
    display: flex;
    a{
      display: block;
      width: 24px;
      line-height: 0;
      margin-right: 20px;
      svg{
        width: 100%;
      }
      .fill{
        fill: $color-gray-400;
      }
      .stroke{
        stroke: $color-gray-400;
      }
    }
    &-wrapper{
      padding: 15px 30px 0 30px;
    }
  }
  &.active-menu{
    &:after{
      border-bottom-color: rgba(1,1,1,0);
    }
    .header-burger{
      .close{
        span{
          width: 22px;
          &:nth-child(1){
            transition-delay: 0.5s;
          }
          &:nth-child(2){
            transition-delay: 0.58s;
          }
        }
      }
      .open{
        span{
          width: 0;
          &:nth-child(1){
            transition-delay: 0s;
          }
          &:nth-child(2){
            transition-delay: 0.05s;
          }
          &:nth-child(3){
            transition-delay: 0.1s;
          }
        }
      }
    }
  }
  &.active-notification{
    .header-notification{
      display: block;
    }
  }
  &.active-search{
    .header-layer{
      visibility: visible;
      opacity: 1;
    }
    .header-search{
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
    .header-search-layer{
      display: block;
    }
  }
  &.active-basket{
    .header-layer{
      visibility: visible;
      opacity: 1;
      z-index: 9;
    }
    .header-basket{
      transform: none;
    }
    .header-basket-layer{
      display: block;
    }
  }
  &.active-menu,
  &.active-basket,
  &.active-search,
  &.scrolled{
    &:after{
      visibility: visible;
      opacity: 1;
    }
    .header-inner{
      .logo{
        .dark{
          visibility: visible;
          opacity: 1;
        }
        .light{
          visibility: hidden;
          opacity: 0;
        }
      }
      .nav-basic{
        .stroke{
          stroke: $color-dark-800;
        }
        .fill{
          fill: $color-dark-800;
        }
      }
      .nav .menu > li > a {
        color: $color-dark-800;
      }
      .header-burger {
        .open,
        .close{
          span{
            background-color: $color-dark-800;
          }
        }
      }
    }
  }
}
.header{
  &-basket{
    background-color: #fff;
    width: 100%;
    max-width: 460px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    transform: translateX(100%);
    transition: all .4s ease-out;
    z-index: 99;
    &-inner{
      height: 100vh;
      padding: 20px 20px 150px 20px;
      overflow: auto;
    }
    &-total{
      padding: 20px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      .subtotal{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        padding-top: 20px;
        border-top: 1px solid #E8E2EF;
        .title{
          margin-right: 15px;
        }
      }
    }
    &-layer{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 98;
      display: none;
    }
    &-title{
      font-size: 20px;
      position: relative;
      padding-left: 100px;
      padding-right: 100px;
      text-align: center;
      .btn-close{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &-quantity{
      font-size: 12px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      &-mob{
        display: none;
      }
    }
    &-empty{
      padding-top: 100px;
      text-align: center;
      .icon{
        display: block;
        width: 158px;
        line-height: 0;
        margin: 0 auto 40px auto;
        img{
          width: 100%;
        }
      }
      .text-in{
        margin-bottom: 60px;
        max-width: 250px;
        display: inline-block;
      }
    }
  }
}
.not-main-page{
  .header{
    &:after{
      visibility: visible;
      opacity: 1;
    }
    .header-inner{
      .logo{
        .dark{
          visibility: visible;
          opacity: 1;
        }
        .light{
          visibility: hidden;
          opacity: 0;
        }
      }
      .nav-basic{
        .stroke{
          stroke: $color-dark-800;
        }
        .fill{
          fill: $color-dark-800;
        }
      }
      .nav .menu > li > a {
        color: $color-dark-800;
      }
      .header-burger {
        .open,
        .close {
          span {
            background-color: $color-dark-800;
          }
        }
      }
    }
  }
}
.dropdown-lang{
  background: #fff;
  box-shadow: 8px 8px 29px rgba(104, 104, 173, 0.09);
  border-radius: 65px;
  position: fixed;
  left: 40px;
  bottom: 140px;
  z-index: 55;
  display: flex;
  &:after{
    content: '';
    width: 1px;
    height: 30px;
    background-color: #E8E2EF;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &-content{
    cursor: pointer;
    position: relative;
    &.lf{
      padding: 23px 14px 23px 20px;
    }
    &.rg{
      padding: 23px 20px 23px 14px;
    }
    .inner{
      padding-right: 28px;
      position: relative;
      height: 22px;
      display: flex;
      align-items: center;
      &:after{
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background-image: url('../img/icons/icon-arrow-bt-0291.svg');
        background-size: 100%;
        background-position: center;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        transition: $transition;
      }
      .icon{
        display: inline-block;
        width: 34px;
        line-height: 0;
        position: relative;
        img{
          width: 100%;
        }
      }
    }
    .list{
      background-color: #fff;
      position: absolute;
      min-width: 100%;
      padding: 10px 0;
      left: 0;
      top: calc(100% + 4px);
      border-radius: 6px;
      cursor: default;
      box-shadow: 8px 8px 29px rgba(104, 104, 173, 0.09);
      visibility: hidden;
      transform: translateY(15px);
      opacity: 0;
      transition: $transition;
      transition-delay: 0.2s;
      li{
        margin-bottom: 2px;
        &:last-child{
          margin-bottom: 0;
        }
        a{
          display: block;
          padding: 4px 14px;
          text-decoration: none;
          color: inherit;
          cursor: pointer;
          line-height: 1.2em;
        }
        .icon{
          display: inline-block;
          line-height: 0;
          width: 32px;
          vertical-align: middle;
          img{
            width: 100%;
          }
        }
        &.active{
          pointer-events: none;
          background-color: $color-lilac-300;
          a{
            pointer-events: none;
          }
        }
      }
    }
  }
}

/*==========  Hover  ==========*/

@media (min-width: 991px){
  .header:hover{
    &:after{
      visibility: visible;
      opacity: 1;
    }
    .header-inner{
      .logo{
        .dark{
          visibility: visible;
          opacity: 1;
        }
        .light{
          visibility: hidden;
          opacity: 0;
        }
      }
      .nav-basic{
        .stroke{
          stroke: $color-dark-800;
        }
        .fill{
          fill: $color-dark-800;
        }
      }
      .nav .menu > li > a {
        color: $color-dark-800;
      }
    }
  }
  .header-inner .nav .menu > li > a:hover{
    opacity: 0.8;
  }
  .header-inner .nav .sub-menu-item a:hover{
    opacity: 0.8;
  }
  .header-inner .nav .menu > li:hover .sub-menu{
    transition-delay: 0s;
    opacity: 1;
    visibility: visible;
    transform: none;
  }
  .header-inner .nav-basic .item:hover{
    .fill{
      fill: $color-dark-500;
    }
    .stroke{
      stroke: $color-dark-500;
    }
  }
  .dropdown-lang-content .list li a:hover{
    background-color: $color-lilac-300;
  }
  .dropdown-lang-content:hover{
    .inner:after{
      transform: translateY(-50%) rotate(-180deg);
    }
    .list{
      transition-delay: 0s;
      visibility: visible;
      opacity: 1;
      transform: none;
    }
  }
}

/*==========  MEDIA QUERIES  ==========*/

@media (max-width: 1600px){
  .header {
    &-inner {
      height: 80px;
      .logo {
        width: 160px;
        flex: 0 0 160px;
      }
      .nav {
        .menu {
          > li {
            margin-right: 20px;
          }
        }
        .sub-menu-item {
          li {
            line-height: 1.5em;
          }
        }
      }
      .nav-basic {
        .item {
          margin-right: 20px;
        }
      }
    }
    &-search {
      &-input {
        .input {
          padding-left: 36px;
          padding-right: 36px;
        }
        .icon {
          width: 24px;
        }
      }
    }
    &-notification {
      padding: 10px 50px 8px 50px;
    }
  }
  .dropdown-lang{
    left: 50px;
    bottom: 120px;
    &-content{
      &.lf {
        padding: 16px 14px 16px 16px;
      }
      &.rg {
        padding: 16px 16px 16px 14px;
      }
      .inner {
        padding-right: 24px;
        .icon {
          width: 28px;
        }
        &:after {
          width: 18px;
          height: 18px;
        }
      }
      .list {
        li {
          .icon {
            line-height: 0;
            width: 28px;
          }
        }
      }
    }
  }
}

@media (max-width: 1368px){
  .header {
    &-inner {
      .nav {
        .menu {
          > li {
            margin-right: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px){
  .header {
    &-inner {
      height: 60px;
      .nav {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 78px 0 24px 0;
        background-color: #fff;
        height: 100%;
        z-index: -1;
        overflow-y: auto;
        transform: translateX(100%);
        transition: 0.48s;
        .menu{
          > li{
            display: block;
            margin-right: 0;
            padding-right: 60px;
            position: relative;
            .icon{
              display: block;
              width: 26px;
              line-height: 0;
              position: absolute;
              top: 50%;
              right: 30px;
              transform: translateY(-50%);
              transition: $transition;
              img{
                width: 100%;
              }
            }
            > a{
              font-size: 16px;
              display: block;
              padding: 12px 30px;
            }
            &.active{
              .sub-menu-icon{
                transform: rotate(180deg)!important;
              }
            }
            .label-item{
              display: inline-block;
            }
          }
          &.type2{
            border-top: 1px solid $color-lilac-300;
            > li{
              border-bottom: 1px solid $color-lilac-300;
            }
          }
        }
        .sub-menu{
          display: none;
          &-icon{
            top: 8px!important;
            transform: translateY(0)!important;
          }
          &-mobile{
            display: block;
            padding: 0 30px 0 30px;
            display: none;
            li{
              margin-bottom: 8px;
              padding: 0 15px;
              &:last-child{
                margin-bottom: 0;
              }
              > a{
                font-size: 14px;
                color: $color-dark-800;
                font-weight: 300;
                text-decoration: none;
              }
            }
          }
        }
        &-setting-mobile{
          padding: 16px 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &-languages{
          display: flex;
          a{
            width: 44px;
            height: 44px;
            overflow: hidden;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 32px;
            filter: grayscale(1);
            opacity: 0.3;
            &.active{
              filter: grayscale(0);
              opacity: 1;
            }
            img{
              width: 170%;
              height: 170%;
            }
          }
        }
      }
      .nav-basic {
        margin-left: auto;
        .item {
          flex: 0 0 24px;
          margin-right: 15px;
        }
      }
      .logo {
        width: 142px;
        flex: 0 0 142px;
      }
    }
    &-burger {
      display: block;
    }
    &-notification {
      font-size: 12px;
      padding: 12px 50px 12px 50px;
    }
    &.active-notification {
      .header-inner .nav{
        padding-top: 110px;
      }
    }
    &.active-menu {
      .header-inner .nav {
        transform: none;
      }
    }
  }
  .header {
    &-basket {
      &-inner {
        padding: 20px 15px 150px 15px;
      }
      &-quantity {
        display: none;
        &-mob{
          color: #767185;
          display: inline-block;
        }
      }
      &-title{
        font-size: 20px;
        padding-right: 30px;
        padding-left: 0;
        text-align: left;
        .btn-close{
          left: auto;
          right: 0;
        }
      }
      &-total {
        padding: 20px 15px;
        .subtotal{
          padding-top: 12px;
          margin-bottom: 12px;
        }
      }
    }
  }
  .dropdown-lang{
    display: none;
  }
}

//@media (max-width: 991px){}

//@media (max-width: 768px){}

//@media (max-width: 575px){}




