@import 'global';
@import 'animations';
@import 'spacers';
@import 'section-settings';

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* OTHER ELEMENTS */
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* BG */
.video-bg:after,
.bg:after {
	content: '';
	display: block;
}

.video-bg,
.video-bg:after,
.wr-hd,
.bg,
.bg:after {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.bg {
	background-size: cover;
	background-repeat: no-repeat;
}

.wr-hd {
	overflow: hidden;
	z-index: -1;
}

.video-bg video {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%) scale(1.1);
}

/* Icon arrow */
.icon-arrow {
	position: relative;
	display: inline-block;
	width: 7px;
	height: 7px;
	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 0;
		width: 1px;
		height: 9px;
		background-color: #111111;
	}
	&:before {
		left: 0;
		transform: rotate(-45deg);
	}
	&:after{
		right: 0;
		transform: rotate(45deg);
	}
}

/* Main loader */
#loader-wrapper {
	position: fixed;
	left: 0;
	top: -100px;
	right: 0;
	bottom: -100px;
	background: #fff;
	z-index: 2000;
}

.image-wrapper{
	line-height: 0;
	img {
		max-width: 100%;
	}
	&.right{
		text-align: right;
	}
	&.center{
		text-align: center;
	}
	&.ps-01{
		display: inline-block;
		transform: translateX(50%);
		margin-top: -250px;
	}
}
.video-wrapper{
	line-height: 0;
	video{
		width: 100%;
	}
}

.ps-relative{
	position: relative;
}

.title-wrapper{
	display: flex;
	justify-content: space-between;
	align-items: center;
	.text-in{
		margin-right: 10px;
	}
}

.hr{
	height: 1px;
	background-color: $color-lilac-300;
}
.separate{
	&-hr{
		height: 1px;
		background-color: $color-lilac-300;
	}
	&-or{
		text-align: center;
		position: relative;
		font-size: 14px;
		font-weight: 300;
		color: $color-gray-400;
		&:after,
		&:before{
			content: '';
			position: absolute;
			display: block;
			height: 1px;
			background-color: #E8E2EF;
			width: calc(50% - 40px);
		}
		&:before{
			top: 50%;
			left: 0;
		}
		&:after{
			top: 50%;
			right: 0;
		}
	}
}

.img-container{
	max-width: 96px;
	line-height: 0;
	img{
		max-width: 100%;
	}
	&.size-850{
		max-width: 850px;
	}
	&.size-200{
		max-width: 200px;
	}
	&.center{
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
}

.img-bg-container{
	position: relative;
	padding-bottom: 100%;
	&.size-120{
		padding-bottom: 120%;
	}
}

.wrapper-scroll{
	overflow-y: auto;
	overflow-x: hidden;
	&.mx-1000{
		max-height: 1100px;
	}
}
.overflow-hidden{
	overflow: hidden;
}

.text-with-icon{
	.icon{
		display: inline-block;
		width: 20px;
		line-height: 0;
		margin-right: 10px;
		img{
			width: 100%;
		}
	}
	span{
		vertical-align: middle;
	}
}

.d-none{
	display: none!important;
}
.d-block{
	display: block!important;
}

/*==========  Hover  ==========*/

// @media (min-width: 1200px){}

/*==========  MEDIA QUERIES  ==========*/

@media (max-width: 1600px){
	.image-wrapper{
		&.ps-01 {
			margin-top: -50px;
		}
	}
}

@media (max-width: 1199px){
	.image-wrapper{
		&.xl-center{
			text-align: center;
		}
	}
	.title-wrapper:not(.not-mob){
		display: block;
		.text-in{
			margin-right: 0;
		}
	}
	.wrapper-scroll{
		&.mx-unset-xl{
			max-height: unset;
		}
	}
	.d-none-xl{
		display: none!important;
	}
	.d-block-xl{
		display: block!important;
	}
}

@media (max-width: 991px){
	.image-wrapper{
		&.lg-center{
			text-align: center;
		}
	}
	.d-none-lg{
		display: none!important;
	}
	.d-block-lg{
		display: block!important;
	}
}

// @media (max-width: 768px){}

//@media (max-width: 575px){}


