
/*==========  BUTTONS  ==========*/

.btn {
	display: inline-block;
	vertical-align: bottom;
	text-align: center;
	position: relative;
	cursor: pointer;
	text-decoration: none;
	border: none;
	padding: 0;
	background: none;
	outline: none;
}

.btn-primary {
	font-size: 14px;
	font-weight: 500;
	line-height: 1.7em;
	color: #fff;
	padding: 13px 40px 11px 40px;
	min-width: 175px;
	background-color: $color-dark-900;
	position: relative;
	z-index: 0;
	border-radius: 2px;
	overflow: hidden;
	transition: $transition;
	/*
	&:after{
		content: '';
		display: block;
		width: 0;
		height: 100%;
		background-color: #E6E6E7;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		transition: $transition;
	}
	*/
	span{
		position: relative;
		vertical-align: middle;
	}
	.icon{
		display: inline-block;
		line-height: 0;
		width: 24px;
		margin-right: 8px;
		img{
			width: 100%;
		}
	}
	&:active{
		background-color: rgba(6, 4, 10, 0.15);
	}
	&.disabled{
		color: $color-gray-300;
		background-color: $color-gray-100;
		pointer-events: none;
	}
	&.light{
		padding: 11px 40px;
		background-color: rgba(1,1,1,0);
		border: 1px solid #FFFFFF;
	}
	&.dark{
		padding: 11px 40px;
		color: #06040A;
		background-color: rgba(1,1,1,0);
		border: 1px solid #06040A;
	}
}
.btn-secondary {
	border: 1px solid $color-dark-900;
	font-size: 14px;
	line-height: 1.7em;
	font-weight: 500;
	color: $color-dark-900;
	padding: 12px 20px;
	min-width: 175px;
	position: relative;
	z-index: 0;
	border-radius: 2px;
	overflow: hidden;
	transition: $transition;
	/*
	&:after{
		content: '';
		display: block;
		width: 0;
		height: 100%;
		background-color: #F3F1F6;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		transition: $transition;
	}
	*/
	span{
		position: relative;
		vertical-align: middle;
	}
	.icon{
		display: inline-block;
		line-height: 0;
		width: 24px;
		margin-right: 8px;
		img{
			width: 100%;
		}
	}
	&:active{
		background-color: rgba(131, 115, 165, 0.15);
	}
	&.disabled{
		border: 1px solid $color-gray-300;
		color: $color-gray-300;
		pointer-events: none;
	}
	&.type2{
		font-size: 12px;
		line-height: 1.4em;
		color: $color-dark-900;
		background-color: #fff;
		border-color: $color-dark-500;
		padding: 10px 14px;
		min-width: 145px;
		&:after{
			background-color: $color-lilac-100;
		}
	}
}
.btn-icon{
	padding: 10px;
	min-width: 50px;
	border: 1px solid $color-dark-500;
	border-radius: 2px;
	.icon{
		line-height: 0;
		width: 24px;
		display: block;
		margin: 0 auto;
		img{
			width: 100%;
		}
	}
	.fill{
		fill: $color-dark-500;
		transition: $transition;
	}
	&:after{
		content: '';
		display: block;
		width: 0;
		height: 100%;
		background-color: $color-lilac-100;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		transition: $transition;
	}
	&:active{
		/*
		&:after{
			background-color: $color-dark-500;
		}
		.fill{
			fill: #FFFFFF;
		}
		*/
	}
}

.btn-icon2{
	padding: 9px;
	min-width: 48px;
	background-color: $color-lilac-200;
	border-radius: 2px;
	.icon{
		width: 30px;
		height: 30px;
		line-height: 30px;
		display: block;
		margin: 0 auto;
		text-align: center;
		svg{
			max-width: 100%;
			vertical-align: middle;
		}
		img{
			width: 100%;
		}
		.fill-bg{
			fill: rgba(1,1,1,0);
			transition: $transition;
		}
		.fill-br{
			fill: $color-dark-800;
			transition: $transition;
		}
	}
	&.light{
		background-color: rgba(1,1,1,0);
	}
	&.light2{
		background-color: rgba(255, 255, 255, 0.4);
	}
	&.active{
		.icon{
			.fill-bg{
				fill: $color-dark-700;
			}
			.fill-br{
				fill: $color-dark-700;
			}
		}
	}
	&.disabled{
		background-color: $color-gray-100;
		pointer-events: none;
		.icon{
			.fill-br{
				fill: $color-gray-300;
			}
		}
	}
}

.btn-icon3{
	line-height: 0;
	width: 24px;
	transition: $transition;
	img{
		width: 100%;
	}
	&.md{
		width: 20px;
	}
}

.btn-link {
	line-height: 1.4em;
	font-weight: 400;
	color: $color-dark-700;
	text-align: left;
	&:after{
		content: '';
		display: block;
		height: 1px;
		width: 0;
		position: absolute;
		left: 0;
		bottom: 0;
		transition: $transition;
		background-color: $color-dark-700;
	}
	&:active{
		color: $color-dark-800;
		border-bottom-color: $color-dark-800;
	}
	&.xl{
		font-size: 20px;
	}
	&.md{
		font-size: 14px;
	}
	&.type2{
		font-weight: 500;
		color: $color-dark-800;
	}
}

.btn-link2{
	font-size: 14px;
	line-height: 1.2em;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px 0;
	transition: $transition;
	&:after{
		content: '';
		display: block;
		height: 1px;
		width: 0;
		position: absolute;
		left: 0;
		bottom: 0;
		transition: $transition;
		background-color: #06040A;
	}
	.icon{
		display: inline-block;
		width: 20px;
		line-height: 0;
		margin-right: 6px;
		img{
			width: 100%;
		}
		svg{
			width: 100%;
		}
		.stroke{
			stroke: #06040A;
			transition: $transition;
		}
		.fill{
			fill: #06040A;
			transition: $transition;
		}
	}
	&.type2{
		color: #8768AD;
		&:after{
			background-color: #8768AD;
		}
		.icon{
			.stroke{
				stroke: #8768AD;
			}
			.fill{
				fill: #8768AD;
			}
		}
	}
	&.type3{
		font-weight: 500;
		color: $color-dark-800;
	}
}

.btn-link-icon{
	font-size: 14px;
	color: $color-dark-900;
	padding-right: 38px;
	transition: $transition;
	.icon{
		display: block;
		width: 26px;
		line-height: 0;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		img{
			width: 100%;
		}
	}
	&.left{
		padding-right: 0;
		padding-left: 38px;
		.icon{
			right: auto;
			left: 0;
		}
	}
	&.disabled{
		pointer-events: none;
		color: $color-gray-300;
	}
	&.type2{
		font-weight: 300;
		color: $color-dark-700;
		padding-right: 35px;
		.icon{
			width: 20px;
		}
	}
}

.btn-block {
	display: block;
	width: 100%;
}

.btn-close {
	width: 32px;
	height: 32px;
	font-size: 24px;
	font-weight: 700;
	line-height: 32px;
	color: $color-dark-800;
	position: relative;
	z-index: 1;
	cursor: pointer;
	outline: none;
	display: block;
	text-align: center;
	transition: all .37s ease-out;
	&:after{
		content: "\02715";
	}
	&.md{
		width: 24px;
		height: 24px;
		font-size: 22px;
		line-height: 24px;
	}
}

.btn-wrapper{
	display: flex;
	align-items: center;
	&.left{
		justify-content: flex-start;
	}
	&.right{
		justify-content: flex-end;
	}
	&.center{
		justify-content: center;
	}
	&.full-2{
		.btn{
			flex: 0 0 calc(50% - 7.5px);
			padding-left: 5px;
			padding-right: 5px;
			min-width: 0;
		}
	}
	> *{
		margin-right: 15px;
		&:last-child{
			margin-right: 0;
		}
	}
	&.sm{
		> *{
			margin-right: 5px;
			&:last-child{
				margin-right: 0;
			}
		}
	}
	&.xl{
		> *{
			margin-right: 40px;
			&:last-child{
				margin-right: 0;
			}
		}
	}
}


@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	}
}

.spinner {
	animation: rotate 2s linear infinite;
	z-index: 2;
	width: 50px;
	height: 50px;
	&.xl{
		width: 36px;
		height: 36px;
	}
	&.md{
		width: 24px;
		height: 24px;
	}
	&.sm{
		width: 16px;
		height: 16px;
	}
	.path {
		stroke: #fff;
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}
}
.btn-loading{
	.loading-icon{
		display: none;
	}
	&.loader {
		color: rgba(1, 1, 1, 0);
		pointer-events: none;
		position: relative;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		.loading-icon {
			display: inline-block;
			line-height: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&.none{
		display: none!important;
	}
}

/*==========  Hover  ==========*/

@media (min-width: 992px){
	.btn-primary:hover{
		background-color: rgba(6, 4, 10, 0.1);
		/*
		&:after{
			width: 100%;
			left: auto;
			right: 0;
		}
		*/
	}
	.btn-secondary:not(.type2):hover{
		background-color: rgba(131, 115, 165, 0.1);
		/*
		&:after{
			width: 100%;
			left: auto;
			right: 0;
		}
		*/
	}
	.btn-icon:hover{
		&:after{
			width: 100%;
			left: auto;
			right: 0;
		}
	}
	.btn-link:hover{
		&:after{
			width: 100%;
		}
	}
	.btn-link2:hover{
		&:after{
			width: 100%;
		}
	}
	.btn-link-icon:hover{
		opacity: 0.8;
	}
	.btn-close:hover{
		color: $color-dark-500;
	}
	.btn-icon2:hover{
		.icon{
			.fill-bg{
				fill: $color-dark-500;
			}
			.fill-br{
				fill: $color-dark-500;
			}
		}
	}
	.btn-icon3:hover{
		opacity: 0.8;
	}
}

/*==========  MEDIA QUERIES  ==========*/

@media (max-width: 1600px){
	.btn-primary {
		padding: 10px 20px 8px 20px;
		min-width: 150px;
		&.light {
			padding: 8px 20px;
		}
	}
	.btn-secondary{
		padding: 8px 20px;
		min-width: 150px;
	}
	.btn-close {
		width: 24px;
		height: 24px;
		font-size: 18px;
		line-height: 24px;
	}
	.btn-icon2 {
		padding: 8px;
		min-width: 46px;
	}
}

@media (max-width: 1199px){
	.btn-primary {
		padding: 12px 22px 10px 22px;
		min-width: 175px;
	}
	.btn-secondary {
		padding: 11px 22px 9px 22px;
	}
}

@media (max-width: 991px){
	.btn-link-icon {
		font-size: 12px;
	}
}

//@media (max-width: 768px){}

// @media (max-width: 575px)
