/*==========  SIZE  ==========*/

/* Text size */
body, .text {
	font-size: 16px;
	font-weight: 400;
	line-height: 1.6em;
}
.text{
	&-lg {
		font-size: 16px;
		line-height: 1.8em;
	}
	&-md{
		font-size: 14px;
		line-height: 1.7em;
	}
	&-sm {
		font-size: 12px;
		line-height: 1.6em;
	}
}

/* Headings */

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	color: $color-dark-800;
	font-weight: 300;
}

h1, .h1 {
	font-size: 120px;
	line-height: 1.2em;
}
.h1_2{
	font-size: 120px;
	line-height: 1.2em;
}

h2, .h2 {
	font-size: 40px;
	line-height: 1.2em;
}

h3, .h3 {
	font-size: 30px;
	line-height: 1.2em;
}

h4, .h4 {
	font-size: 24px;
	line-height: 1.2em;
}

h5, .h5 {
	font-size: 20px;
	line-height: 1.4em;
}

h6, .h6 {
	font-size: 14px;
	line-height: 1.4em;
}

/*==========  MEDIA QUERIES  ==========*/

@media (max-width: 1600px){
	h1, .h1 {
		font-size: 80px;
	}
	h2, .h2 {
		font-size: 28px;
	}
	h4, .h4{
		font-size: 20px;
	}
	h5, .h5 {
		font-size: 18px;
	}
}

@media (max-width: 1199px){
	h1, .h1 {
		font-size: 40px;
	}
	h2, .h2 {
		font-size: 24px;
	}
	h4, .h4 {
		font-size: 20px;
	}
	.h4_xl{
		font-size: 14px;
	}
	.h4_xl2{
		font-size: 16px;
	}
	h5, .h5 {
		font-size: 16px;
	}
	.h5_xl{
		font-size: 14px;
	}
	.h5_xl2{
		font-size: 20px;
	}
	.text-md_xl{
		font-size: 12px;
	}
}

@media (max-width: 991px) {
	body, .text {
		font-size: 14px;
		line-height: 1.4em;
	}
}

// @media (max-width: 768px){}

// @media (max-width: 575px)
