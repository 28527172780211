/*-------------------------------------------------------------------------------------------------------------------------------*/
/* Grid */
/*-------------------------------------------------------------------------------------------------------------------------------*/

@media (min-width: 1600px) {
	.col-extra {
		-ms-flex-preferred-size:0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%
	}

	.col-extra-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%
	}

	.col-extra-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%
	}

	.col-extra-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%
	}

	.col-extra-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%
	}

	.col-extra-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%
	}

	.col-extra-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%
	}

	.col-extra-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%
	}

	.col-extra-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%
	}

	.col-extra-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%
	}

	.col-extra-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%
	}

	.col-extra-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%
	}

	.col-extra-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%
	}

	.col-extra-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%
	}

	.order-extra-first {
		-ms-flex-order: -1;
		order: -1
	}

	.order-extra-last {
		-ms-flex-order: 13;
		order: 13
	}

	.order-extra-0 {
		-ms-flex-order: 0;
		order: 0
	}

	.order-extra-1 {
		-ms-flex-order: 1;
		order: 1
	}

	.order-extra-2 {
		-ms-flex-order: 2;
		order: 2
	}

	.order-extra-3 {
		-ms-flex-order: 3;
		order: 3
	}

	.order-extra-4 {
		-ms-flex-order: 4;
		order: 4
	}

	.order-extra-5 {
		-ms-flex-order: 5;
		order: 5
	}

	.order-extra-6 {
		-ms-flex-order: 6;
		order: 6
	}

	.order-extra-7 {
		-ms-flex-order: 7;
		order: 7
	}

	.order-extra-8 {
		-ms-flex-order: 8;
		order: 8
	}

	.order-extra-9 {
		-ms-flex-order: 9;
		order: 9
	}

	.order-extra-10 {
		-ms-flex-order: 10;
		order: 10
	}

	.order-extra-11 {
		-ms-flex-order: 11;
		order: 11
	}

	.order-extra-12 {
		-ms-flex-order: 12;
		order: 12
	}

	.offset-extra-0 {
		margin-left: 0
	}

	.offset-extra-1 {
		margin-left: 8.333333%
	}

	.offset-extra-2 {
		margin-left: 16.666667%
	}

	.offset-extra-3 {
		margin-left: 25%
	}

	.offset-extra-4 {
		margin-left: 33.333333%
	}

	.offset-extra-5 {
		margin-left: 41.666667%
	}

	.offset-extra-6 {
		margin-left: 50%
	}

	.offset-extra-7 {
		margin-left: 58.333333%
	}

	.offset-extra-8 {
		margin-left: 66.666667%
	}

	.offset-extra-9 {
		margin-left: 75%
	}

	.offset-extra-10 {
		margin-left: 83.333333%
	}

	.offset-extra-11 {
		margin-left: 91.666667%
	}
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* Section settings */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.section {
	position: relative;
	z-index: 0;
	&.z-auto{
		z-index: auto;
	}
}
.container-cs{
	padding-right: 75px;
	padding-left: 75px;
	margin-right: auto;
	margin-left: auto;
	&.md{
		max-width: 1010px;
	}
	&.xl{
		max-width: 1360px;
	}
	&.xl-extra{
		max-width: 1610px;
	}
}
.row{
	&.row-0{
		margin-right: 0px;
		margin-left: 0px;
		> [class*=col]{
			padding-right: 0px;
			padding-left: 0px;
		}
	}
	&.row-1{
		margin-right: -1px;
		margin-left: -1px;
		> [class*=col]{
			padding-right: 1px;
			padding-left: 1px;
		}
	}
	&.row-5{
		margin-right: -5px;
		margin-left: -5px;
		> [class*=col]{
			padding-right: 5px;
			padding-left: 5px;
		}
	}
	&.row-20{
		margin-right: -10px;
		margin-left: -10px;
		> [class*=col]{
			padding-right: 10px;
			padding-left: 10px;
		}
	}
	&.row-40{
		margin-right: -20px;
		margin-left: -20px;
		> [class*=col]{
			padding-right: 20px;
			padding-left: 20px;
		}
	}
	&.row-50{
		margin-right: -25px;
		margin-left: -25px;
		> [class*=col]{
			padding-right: 25px;
			padding-left: 25px;
		}
	}
	&.row-70{
		margin-right: -35px;
		margin-left: -35px;
		> [class*=col]{
			padding-right: 35px;
			padding-left: 35px;
		}
	}
	&.row-100{
		margin-right: -50px;
		margin-left: -50px;
		> [class*=col]{
			padding-right: 50px;
			padding-left: 50px;
		}
	}
	&.row-120{
		margin-right: -60px;
		margin-left: -60px;
		> [class*=col]{
			padding-right: 60px;
			padding-left: 60px;
		}
	}
	&.row-180{
		margin-right: -90px;
		margin-left: -90px;
		> [class*=col]{
			padding-right: 90px;
			padding-left: 90px;
		}
	}
}
.wrapper-grid{
	&-30{
		margin-bottom: -30px;
		[class*=col-]{
			margin-bottom: 30px;
		}
	}
}

/*==========  MEDIA QUERIES  ==========*/

@media (max-width: 1600px){
	.container-cs {
		padding-right: 50px;
		padding-left: 50px;
	}
	.row{
		&.row-xl-4{
			margin-right: -2px;
			margin-left: -2px;
			> [class*=col]{
				padding-right: 2px;
				padding-left: 2px;
			}
		}
		&.row-xl-10{
			margin-right: -5px;
			margin-left: -5px;
			> [class*=col]{
				padding-right: 5px;
				padding-left: 5px;
			}
		}
		&.row-xl-16{
			margin-right: -8px;
			margin-left: -8px;
			> [class*=col]{
				padding-right: 8px;
				padding-left: 8px;
			}
		}
		&.row-xl-30{
			margin-right: -15px;
			margin-left: -15px;
			> [class*=col]{
				padding-right: 15px;
				padding-left: 15px;
			}
		}
	}
}

@media (max-width: 1368px){
	.container-cs {
		padding-right: 30px;
		padding-left: 30px;
	}
}

@media (max-width: 1199px){
	.container-cs {
		padding-right: 15px;
		padding-left: 15px;
	}
}

// @media (max-width: 991px){}

// @media (max-width: 768px){}

// @media (max-width: 575px){}
